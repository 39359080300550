// @ts-nocheck
import React, {
  FC,
  RefObject,
  useEffect,
  useRef,
  useState,
  cloneElement,
  Children,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { makeStyles } from '@material-ui/core';
import withFeatureLock from '../../_HOCs/withFeatureLock';
import { Spinner } from '../Spinner/Spinner';
import './TabContainer.scss';
import _ from 'lodash';

interface iTabContainerProps {
  defaultTabIndex?: number;
  onChangeTab: (index: number, title: any) => void;
  children: any[];
}
interface iTabContainerRef {
  setTab: (title: string) => void;
}

export const TabContainer = forwardRef<iTabContainerRef, iTabContainerProps>(
  ({ onChangeTab, defaultTabIndex = 0, children }, ref) => {
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(null);
    const [activeTabIndex, setActiveTabIndex] = useState(null);
    const defaultTabRef = useRef(null);

    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);

    const shownChildren = _.compact(
      children.map((child) => {
        if (!child || (typeof child.props.show === 'boolean' && !child.props.show)) {
          return;
        }
        return child;
      })
    );

    const tabs = shownChildren
      .filter((child) => child && !(typeof child.props.show === 'boolean' && !child.props.show))
      .map((child, index) => {
        return cloneElement(child, {
          key: index,
          tabRef: index === defaultTabIndex ? defaultTabRef : undefined,
          isActive: activeTabIndex === index,
          onClick: (e) => {
            setActiveTab(child.props.children);
            setActiveTabIndex(index);
            onChangeTab(index, child.props.title);
          },
          ...child.props,
        });
      });

    useEffect(() => {
      setActiveTabIndex(defaultTabIndex);
    }, [defaultTabIndex]);

    useEffect(() => {
      setActiveTab(tabs[activeTabIndex]?.props?.children);
    }, [activeTabIndex]);

    useImperativeHandle(ref, () => ({
      setTab(title) {
        const tab = shownChildren.find((tab) => tab.props.title === title);
        if (tab) {
          setActiveTab(tab.props.children);
          const index = shownChildren.indexOf(tab);
          setActiveTabIndex(index);
        }
      },
    }));

    const tabContainerRef = useRef(null);
    const handleScroll = (direction) => {
      const container = tabContainerRef.current;
      if (container) {
        const scrollAmount = direction === 'left' ? -200 : 200;
        container.scrollTo({
          left: container.scrollLeft + scrollAmount,
          behavior: 'smooth',
        });
      }
    };

    const handleScrollVisibility = () => {
      const container = tabContainerRef.current;
      if (container) {
        setShowLeftArrow(container.scrollLeft > 0);
        setShowRightArrow(container.scrollLeft < container.scrollWidth - container.clientWidth);
      }
    };

    useEffect(() => {
      handleScrollVisibility();
      const container = tabContainerRef.current;
      if (container) {
        container.addEventListener('scroll', handleScrollVisibility);
      }
      return () => {
        if (container) {
          container.removeEventListener('scroll', handleScrollVisibility);
        }
      };
    }, []);

    return (
      <div className="tab-container">
        <div className="tab-overflow">
          <div className="tab-header">
            {showLeftArrow && (
              <div className="arrow" onClick={() => handleScroll('left')}>
                {'<'}
              </div>
            )}
            <div className="tabs-wrapper" ref={tabContainerRef}>
              {tabs}
            </div>
            {showRightArrow && (
              <div className="arrow" onClick={() => handleScroll('right')}>
                {'>'}
              </div>
            )}
          </div>

          <div className="tab-body">
            {isLoading && (
              <div className="spinner-backing">
                <div className="spinner-container">
                  <Spinner />
                </div>
              </div>
            )}
            <div className={`tab-content${isLoading ? ' loading' : ''}`}>
              {Children.map(activeTab, (child, index) => {
                return (
                  <React.Fragment key={index}>
                    {cloneElement(child, {
                      setIsLoading,
                      isLoading,
                      ...child.props,
                    })}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export interface iTabChildProps {
  // set spinner
  setIsLoading?: (isLoading: boolean) => void;
  isLoading?: boolean;
}

interface iTabProps {
  title: string | any;
  show?: boolean;
  isActive?: boolean; // added by TabContainer
  onClick?: () => void; // added by TabContainer
  tabRef?: RefObject<HTMLDivElement>; // added by TabContainer
  isNew?: boolean;
  className?: string;
}

export const Tab = withFeatureLock(({ title, onClick, isActive, isNew = false, tabRef, className }: iTabProps) => {
  const classes = useStyles();

  return (
    <div className={`tab${isActive ? ' active' : ''} ${className || ''}`} onClick={onClick} ref={tabRef}>
      {isNew && (
        <div className={classes.newWrapper}>
          <div className={classes.newText}>NEW</div>
        </div>
      )}
      <span>{title}</span>
    </div>
  );
}, 'tab');

const useStyles = makeStyles({
  newWrapper: {
    display: 'flex',
    position: 'absolute',
    right: 0,
    top: '10px',
    width: '29px',
    height: '15px',
    backgroundImage: 'linear-gradient(103deg, #ff815d 0%, #ff4138 100%)',
    borderRadius: '10px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  newText: {
    display: 'inline-block',
    color: '#ffffff',
    fontWeight: 700,
    lineHeight: '15px',
    fontSize: '8px',
  },
});
