import React, { Component, useState } from 'react';
import { AuthContainer } from './AuthContainer';
import { TextInput } from '../Shared/Inputs/TextInput';
import './ResetPasswordStart.scss';
import { BsArrowLeftShort } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { Button } from '../Shared/Buttons/Button';
import services from '../../API/services';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Toast } from '../Shared/Toaster/Toast';
import { Title, Text } from '../Shared/Typography/Typography';
import { analytics } from '../../analytics/analytics';
import { ToastTypes } from '../Shared/Toaster/Toaster.actions';
import { toasterService } from '../Shared/Toaster/Toaster.service';

const initialValues = {
  email: '',
};

const validationSchema = yup.object({
  email: yup.string().email('Not valid email format').required('The field can’t be empty'),
});

export const ResetPasswordStart = () => {
  const [isSent, setIsSent] = useState(false);
  const [serverError, setServerError] = useState('');

  const handleSubmit = async ({ email }) => {
    const response = await services.resetPasswordStart(email);
    if (response.status === 200) {
      if (!isSent) {
        setIsSent(true);
        analytics.forgetPassword();
      } else {
        toasterService.addSuccessToast('Password reset instructions have been resent');
      }
    } else {
      setServerError(response.data.message);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <AuthContainer>
      <div className="reset-password">
        {serverError && (
          <div className="mb-4">
            <Toast type={ToastTypes.ERROR} message={serverError} />
          </div>
        )}
        <Link
          className="text-uppercase font-weight-bold d-flex align-items-center mb-4 reset-password_back"
          to="/login"
        >
          <BsArrowLeftShort className="reset-password_back-icon" />
          <Text className="align-middle" weight="500">
            Back
          </Text>
        </Link>
        <div className="mb-4">
          <Title>Reset Password</Title>
        </div>
        {!isSent && (
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-4">
              <Text>Enter the email address associated with your account</Text>
            </div>
            <div className="mb-4">
              <TextInput
                id="reset-password_email"
                label="Email"
                type="email"
                placeholder="Enter Your Email"
                value={formik.values.email}
                onChange={formik.handleChange('email')}
                onBlur={formik.handleBlur('email')}
                error={formik.errors.email}
                touched={formik.touched.email}
              />
            </div>
            <div>
              <Button type="submit" disabled={!(formik.isValid && formik.dirty)}>
                Reset
              </Button>
            </div>
          </form>
        )}
        {isSent && (
          <div className="reset-password_success-message">
            Password reset instructions have been sent to {formik.values.email}
            <div className="mt-4">
              <Button onClick={() => formik.handleSubmit()}>Resend email</Button>
            </div>
          </div>
        )}
      </div>
    </AuthContainer>
  );
};
