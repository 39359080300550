import React from 'react';
import { FiCheck } from 'react-icons/fi';
import { ErrorText } from '../Typography/Typography';
import './Checkbox.scss';

export const Checkbox = ({ id, className = '', checked, children, error = '', touched = false, ...props }) => {
  return (
    <div className={`ireflect-checkbox ${error && touched && 'error'}`}>
      <div className={`d-flex align-items-center`}>
        <label htmlFor={id} className={`ireflect-checkbox_input mr-3 ${checked ? 'active' : 'disabled'} ${className}`}>
          <FiCheck className="ireflect-checkbox_checkmark" />
          <input id={id} className="ireflect-checkbox_hidden" type="checkbox" checked={checked} {...props} />
        </label>
        <div className="d-inline-block ">{children}</div>
      </div>
      {touched && error && <ErrorText>{error}</ErrorText>}
    </div>
  );
};
