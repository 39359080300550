import React, { useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AggregationUnit, iUser, iCheckinsAggregation, iWheel } from '../../../../API/interfaces';
import SegmentTags from '../../common/SegmentTags/SegmentTags';
import { DateRangeLabel, iDateRangeOptionData } from '../../common/DateRangeDropdown/DateRangeDropdown';
import AggregationService from '../../common/AggregationService';
import { uniqBy } from 'lodash';
import styled from 'styled-components';
import { SupportClient } from '../../../../API/support.client';
import { Text } from '../../../Shared/Typography/Typography';
import { Spinner, SpinnerBackground } from '../../../Shared/Spinner/Spinner';
import { tagsList } from './TagList';
import { shuffle } from 'd3';
import { ResourcesArticle } from '../../../Support/ResourcesArticle';
import { GRADIENTS } from '../../../Shared/colors';
import './SuggestedResources.scss';
import NoResources from '../../common/NoResources/NoResources';

interface iResourcesProps extends RouteComponentProps {
  wheel: iWheel;
  user: iUser;
  showCheckinModal: () => void;
  isAdmin?: boolean;
}

interface iResourcesState {
  activeSegmentId: string | null;
  activeCheckinDate: string | null;
  activeCheckin: iCheckinsAggregation | null;
  checkins: Array<iCheckinsAggregation> | null;
  dateRange: iDateRangeOptionData;
}

const SuggestedResources: React.FC<iResourcesProps> = ({ wheel, user, showCheckinModal, isAdmin }) => {
  const [state, setState] = useState<iResourcesState>({
    activeSegmentId: null,
    activeCheckin: null,
    activeCheckinDate: null,
    checkins: null,
    dateRange: {
      label: 'Latest check ins' as DateRangeLabel,
      from: null,
      to: null,
      unit: 'none' as AggregationUnit,
    },
  });

  const [data, setData] = useState({});
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [postsLoaded, setPostsLoaded] = useState(false);
  const [segmentPills, setSegmentPills] = useState([]);

  const { activeSegmentId, checkins, activeCheckin, dateRange } = state;

  const aggregationService = new AggregationService(wheel);

  const setActiveSegmentId = (segmentId) => {
    setState((prevState) => ({ ...prevState, activeSegmentId: segmentId }));
  };

  const loadData = async () => {
    const checkinsResponse = await aggregationService.loadData({
      wheelId: wheel.id,
      userId: user._id,
      from: dateRange.from?.toISODate() || undefined,
      to: dateRange.to?.toISODate() || undefined,
      unit: dateRange.unit,
    });

    const checkins = aggregationService.mapCheckinsResponse(checkinsResponse);
    const lastCheckin = checkins[0];

    setState((prevState) => ({
      ...prevState,
      activeCheckin: lastCheckin || null,
      activeCheckinDate: lastCheckin?.dateRange || null,
      checkins,
    }));
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (activeCheckin || isAdmin) {
      if (!postsLoaded) {
        setPostsLoaded(true);
        fetchPosts();
      }
    }

    async function fetchPosts() {
      setLoading(true);
      await fetchPostsByTags();
      setLoading(false);
    }
  }, [activeCheckin, isAdmin]);

  const fetchPostsByTags = async () => {
    const segmentNames = wheel.segments.sort((a, b) => a.id.localeCompare(b.id)).map(({ name }) => name);
    setSegmentPills(segmentNames);

    const segmentIds = wheel.segments.reduce(
      (acc, { id }) => ({
        ...acc,
        [id]: [],
      }),
      {}
    );

    const relevantTags = tagsList
      .map(({ title, tag }) => ({
        title: title.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase()),
        tag,
      }))
      .filter(({ title }) => segmentNames.includes(title))
      .map((item) => ({
        ...item,
        index: segmentNames.indexOf(item.title),
      }))
      .sort((a, b) => a.index - b.index);

    try {
      const fetchedData = await Promise.all(relevantTags.map(({ tag }) => SupportClient.getResourcesByTags(tag, 100)));

      fetchedData.forEach((data, index) => {
        const parsedData = data.map((article) => ({
          ...SupportClient.parseArticles(article),
          tag: relevantTags[index].title,
        }));
        segmentIds[Object.keys(segmentIds)[relevantTags[index].index]] = parsedData;
      });
      setData(segmentIds);
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };

  const tagPosts: any[] = useMemo(
    () => shuffle(activeSegmentId === null ? uniqBy(Object.values(data).flat(), 'id') : data[activeSegmentId]),
    [activeSegmentId, data]
  );

  const fakeScores = wheel.segments
    .map((item) => ({
      segmentId: item.id,
      segmentName: item.name,
      description: item.description,
      score: 0,
      scoreComments: null,
    }))
    .sort((a, b) => a.segmentId.localeCompare(b.segmentId));

  if (checkins?.length === 0 && !isAdmin) {
    return <NoResources wheel={wheel} showCheckinModal={showCheckinModal} />;
  }

  return article ? (
    <TabWrapper>
      <ResourcesArticle article={article} index={segmentPills?.indexOf(article?.tag)} back={() => setArticle(null)} />
    </TabWrapper>
  ) : (
    <div className="suggested-resources">
      {loading ? (
        <SpinnerBackground height="50vh">
          <Spinner />
        </SpinnerBackground>
      ) : (
        <>
          <SegmentTags
            averageScore={activeCheckin ? activeCheckin.averageScore : 0}
            scores={activeCheckin ? activeCheckin.scores : fakeScores}
            activeSegmentId={activeSegmentId}
            onClick={setActiveSegmentId}
          />
          <PostsWrapper>
            {!tagPosts.length ? (
              <Message>There are no resources associated with this segment!</Message>
            ) : (
              tagPosts.map((p) => {
                const segmentIndex = segmentPills?.indexOf(p?.tag);
                return (
                  <Post key={p.id} image={p.image} onClick={() => setArticle(p)}>
                    <SegmentPill index={segmentIndex}>{p.tag}</SegmentPill>
                    <PostInfo>
                      <Text size="18px" color="#ffffff" lineHeight={1.33} weight={500} className="mb-2">
                        {p.title}
                      </Text>
                      <Text size="14px" color="#ffffff" weight={300}>
                        {p.date}
                      </Text>
                    </PostInfo>
                  </Post>
                );
              })
            )}
          </PostsWrapper>
        </>
      )}
    </div>
  );
};

export default withRouter(SuggestedResources);

const PostsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: space-between;
`;

const Post = styled.div<any>`
  flex: 0 0 calc(33.3% - 15px);
  margin-bottom: 30px;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position: center center;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  @media (max-width: 768px) {
    flex: 0 0 100%;
  }
  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

const Message = styled.div`
  margin-top: 50px;
  width: 100%;
  text-align: center;
`;

const PostInfo = styled.div<any>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.75) 25%,
    rgba(0, 0, 0, 0) 75%,
    rgba(0, 0, 0, 0) 100%
  );
  padding: 24px;
`;

const TabWrapper = styled.div`
  padding: 50px;
  position: relative;
  width: 100%;
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SegmentPill = styled.div<any>`
  position: absolute;
  top: 24px;
  left: 24px;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 12px;
  border-radius: 16px;
  color: #ffffff;
  background-image: ${(props) => {
    if (props.index !== null) {
      const gradientIndex = props.index % GRADIENTS.length;
      return `linear-gradient(116deg, ${GRADIENTS[gradientIndex][0]}, ${GRADIENTS[gradientIndex][1]} 87%)`;
    }
  }};
`;
