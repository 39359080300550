import React from 'react';
import './index.css';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@material-ui/core';
import { BreakpointProvider } from 'react-socks';
import { QueryClient, QueryClientProvider } from 'react-query';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { QueryParamProvider } from 'use-query-params';
import ireflectTheme from './mui.theme';
import { persistor, store } from './Redux/store';
import * as serviceWorker from './serviceWorker';

import Dashboard from './Components/Dashboard/DashboardNew';
import WheelPage from './Components/WheelPage/WheelPage';
import { resizeWindow } from './Redux/Actions/Actions';
import Settings from './Components/Settings/Settings';
import WheelSettings from './Components/WheelSettings/WheelSettings';
import PublicWheelPage from './Components/WheelPage/PublicWheelPage/PublicWheelPage';
import FeaturesPage from './Components/FeaturesPage/FeaturesPage';
import WheelTemplatesPage from './Components/WheelTemplatesPage/WheelTemplatesPage';
import { SignUpForm } from './Components/Auth/SignUp/SignUpForm';
import { ResetPasswordStart } from './Components/Auth/ResetPasswordStart';
import { ResetPasswordComplete } from './Components/Auth/ResetPasswordComplete';
import { Login } from './Components/Auth/Login';
import { ToasterComponent } from './Components/Shared/Toaster/Toaster';
import { PrivateRoute } from './Components/Auth/PrivateRoute';
import { JoinWheel } from './Components/JoinWheel/JoinWheel';
import { Education } from './Components/Education/Education';
import TemplateSettings from './Components/TemplateSettings/TemplateSettings';
import CreateWheelPage from './Components/CreateWheelPage/CreateWheelPage';
import CreateTemplatePage from './Components/CreateWheelPage/CreateTemplatePage';
import App from './Components/App/App';
import { Onboarding } from './Components/Auth/Onboarding/Onboarding';
import ScrollToTop from './Components/Shared/ScrollToTop/ScrollToTop';
import withLazyLoading from './Components/_HOCs/withLazyLoading';
import { JoinAccount } from './Components/Invitations/JoinAccount';
import { InvitationsPage } from './Components/Invitations/InvitationsPage';
import { JoinFolder } from './Components/Invitations/JoinFolder';
import { msalConfig } from './Components/Auth/SSO/msal/msal.config';
import { Support } from './Components/Support/Support';
import Insights from './Components/Insights';

const WheelImage = withLazyLoading(() => import('./Templates/WheelImage'));
const WheelMemberImage = withLazyLoading(() => import('./Templates/WheelMemberImage'));
const WheelReport = withLazyLoading(() => import('./Templates/Report/WheelReport'));

window.recaptchaOptions = {
  lang: 'en-GB',
  useRecaptchaNet: true,
  removeOnUnmount: false,
};

window.onresize = () => {
  store.dispatch(resizeWindow(document.querySelector('body').offsetWidth));
};

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

const queryClient = new QueryClient();

const dndBackend = HTML5Backend || TouchBackend;

// TODO rework this
const routing = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={ireflectTheme}>
        <Router>
          <QueryClientProvider client={queryClient}>
            <QueryParamProvider ReactRouterRoute={Route}>
              <MsalProvider instance={msalInstance}>
                <ScrollToTop>
                  <BreakpointProvider>
                    <DndProvider backend={dndBackend}>
                      <App>
                        <div className="app-content">
                          <Switch>
                            <Route exact path="/" component={Login} />
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/sign-up" component={SignUpForm} />
                            <Route exact path="/onboarding" component={Onboarding} />

                            <Route
                              exact
                              path="/reset-password"
                              render={() => <Redirect to="/reset-password-start" />}
                            />
                            <Route exact path="/reset-password-start" component={ResetPasswordStart} />
                            <Route exact path="/reset-password-complete" component={ResetPasswordComplete} />
                            <Route exact path="/public/wheel/:wheelId" component={PublicWheelPage} />
                            <Route exact path="/share" component={JoinWheel} />
                            <Route exact path="/join/:id" component={JoinAccount} />
                            <Route exact path="/join-folder/:id" component={JoinFolder} />
                            <Route exact path="/join-folder/:id/:role" component={JoinFolder} />

                            <PrivateRoute path="/dashboard" component={Dashboard} />
                            <PrivateRoute path="/create-wheel" component={CreateWheelPage} />
                            <PrivateRoute path="/create-template" component={CreateTemplatePage} />
                            <PrivateRoute exact path="/wheel/:id/settings" component={WheelSettings} />
                            <PrivateRoute exact path="/wheel/:wheelId" component={WheelPage} />
                            {/* backward compatibility for old links */}
                            <Route
                              exact
                              path="/wheel/:id/member/:memberId"
                              render={(props) => <Redirect to={`/wheel/${props.match.params.id}`} />}
                            />

                            <PrivateRoute exact path="/invitations" component={InvitationsPage} />
                            <PrivateRoute exact path="/templates" component={WheelTemplatesPage} />
                            <PrivateRoute exact path="/template/:id/settings" component={TemplateSettings} />
                            <PrivateRoute exact path="/insights" component={Insights} />

                            <PrivateRoute exact path="/settings" component={Settings} />
                            <PrivateRoute exact path="/share/:id" component={Dashboard} />
                            <PrivateRoute exact path="/features" component={FeaturesPage} />

                            {/* templates */}
                            <Route exact path="/wheel-member-image" component={WheelMemberImage} />
                            <Route exact path="/wheel-image" component={WheelImage} />
                            <Route exact path="/wheel-report" component={WheelReport} />

                            {/*Support*/}
                            <Route exact path="/support" component={Support} />

                            {/* redirect to home */}
                            <Route path="" render={() => <Redirect to="/" />} />
                          </Switch>
                          <ToasterComponent />
                          <Education />
                        </div>
                      </App>
                    </DndProvider>
                  </BreakpointProvider>
                </ScrollToTop>
              </MsalProvider>
            </QueryParamProvider>
          </QueryClientProvider>
        </Router>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
