import './AuthContainer.scss';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FC } from 'react';
import styled from 'styled-components';
import { ResponsiveConstants } from '../Shared/responsive-constants';
import defaultBg from '../../Assets/images/auth-bg-image.png';
import authImage from '../../Assets/images/auth-image.png';
import logo from '../../Assets/images/logo-white.png';

type AuthContainerProps = RouteComponentProps;

const AuthContainerComponent: FC<AuthContainerProps> = ({ children }) => {
  return (
    <div className="auth-container">
      <BackgroundImage>
        <LogoImage src={logo} />
        <AuthImage src={authImage} />
      </BackgroundImage>
      <div className="auth-container_content">{children}</div>
    </div>
  );
};

const BackgroundImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background-size: cover;
  @media all and (max-width: ${ResponsiveConstants.mw_xl}) {
    display: none;
  }

  background-image: url(${defaultBg});
`;

const LogoImage = styled.img`
  width: 200px;
  margin-bottom: 32px;
`;

const AuthImage = styled.img`
  width: 80%;
`;

export const AuthContainer = withRouter(AuthContainerComponent);
