import React from 'react';
import { connect } from 'react-redux';
import { iUser } from '../../API/interfaces';
import { ManageContainer } from '../_Containers/ManageContainer';
import PasswordForm from './PasswordForm/PasswordForm';
import SettingsForm from './SettingsForm/SettingsForm';
import './Settings.scss';
import { AccountInfo } from './AccountInfo';

interface iSettingsProps {
  user: iUser;
}

const Settings = ({ user }: iSettingsProps) => (
  <ManageContainer>
    <div className="settings">
      <div className={'card-list'}>
        <div className="container">
          {user.account && (
            <div className="mb-4">
              <div className="row">
                <div className="col-12">
                  <div className="title">Account</div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <AccountInfo account={user.account} accountRole={user.accountRole} />
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <div className="title">Settings</div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6">
              <SettingsForm user={user} />
            </div>
          </div>
          <div className="pwd">
            <div className="row">
              <div className="col-12">
                <div className="title">Password</div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">
                <PasswordForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ManageContainer>
);

const mapStateToProps = (state) => ({
  user: state.userRD.user || {},
});

export default connect(mapStateToProps)(Settings);
