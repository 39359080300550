import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Checkbox as MuiCheckbox,
  Dialog,
  DialogActions,
  makeStyles,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { iUser } from '../../../API/interfaces';
import services from '../../../API/services';
import { toasterService } from '../../Shared/Toaster/Toaster.service';
import { analytics } from '../../../analytics/analytics';
import { toggleQuestionnaire } from '../../../Redux/Actions/Actions';
import Features, { FeatureType } from '../../FeaturesPage/Features';
import { Button, TransparentButton } from '../../Shared/Buttons/Button';
import ThanksModal from './ThanksModal';

interface iQuestionnaireModalProps {
  user: iUser;
  onClose: () => void;
  toggleQuestionnaire: (show: boolean) => void;
}

const QuestionnaireModal = ({ user, onClose, toggleQuestionnaire }: iQuestionnaireModalProps) => {
  const classes = useStyles();
  const [features, setFeatures] = useState<Array<FeatureType>>([]);
  const [message, setMessage] = useState('');
  const [showThanksModal, setShowThanksModal] = useState(false);
  const checkBoxOnClick = (checked: boolean, featureType) => {
    const newFeatures = checked ? [...features, featureType] : features.filter((f) => f !== featureType);
    setFeatures(newFeatures);
  };
  const sendAnswer = async (requestDemo: boolean) => {
    onClose();
    try {
      await services.sendQuestionnaireResult({
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
        features,
        message,
        requestDemo,
      });
    } catch (e: any) {
      toasterService.addErrorToast(e.message);
      return;
    }

    toasterService.addSuccessToast('Your request was sent successfully');
    requestDemo && analytics.requestDemo('questionnaire');
    toggleQuestionnaire(false);
  };

  if (showThanksModal) {
    return <ThanksModal onClick={sendAnswer} />;
  }

  return (
    <Dialog open={true} classes={{ paper: classes.dialog }}>
      <Typography variant="h4" className={classes.title}>
        Would any of these features help you?
      </Typography>

      <div className={classes.featuresList}>
        {Features.map((f) => (
          <div key={f.type} className={classes.feature}>
            <Checkbox onChange={(e) => checkBoxOnClick(e.target.checked, f.title)} />
            <div>
              <Typography className={classes.featureTitle}>{f.title}</Typography>
              <Typography className={classes.featureDescr}>{f.description}</Typography>
            </div>
          </div>
        ))}
      </div>

      <Typography className={classes.text}>
        We're always listening to feedback and planning new features to help you. Are there any features you think would
        make ireflect better for you and your team?
      </Typography>

      <TextField
        required
        classes={{ root: classes.textField }}
        placeholder="Write your ideas here..."
        variant="outlined"
        multiline
        rows={4}
        onChange={(e) => setMessage(e.target.value)}
      />

      <DialogActions className={classes.buttons}>
        <TransparentButton className={classes.button} onClick={onClose}>
          CANCEL
        </TransparentButton>
        <Button
          className={classes.button}
          onClick={() => {
            setShowThanksModal(true);
            analytics.questionnaireSend();
          }}
        >
          SEND
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  user: state.userRD.user,
});

export default connect(mapStateToProps, { toggleQuestionnaire })(QuestionnaireModal);

const Checkbox = withStyles({
  root: {
    marginRight: '16px',
    color: '#ffe2c1',
    '&$checked': {
      color: '#ff554d',
    },
  },
  checked: {},
})(MuiCheckbox);

const useStyles = makeStyles({
  dialog: {
    padding: '56px 64px 40px',
    color: '#2b2e31',
    '@media(min-device-width: 768px)': {
      minWidth: '656px',
    },
    '@media(max-device-width: 768px)': {
      padding: '32px 24px',
    },
  },
  title: {
    fontSize: '30px',
    '@media(max-device-width: 768px)': {
      fontSize: '16px',
    },
  },
  featuresList: {
    margin: '16px 0',
  },
  feature: {
    display: 'flex',
    alignItems: 'start',
    margin: '16px 0',
  },
  featureTitle: {
    fontSize: '18px',
    lineHeight: 1.33,
    fontWeight: 600,
    '@media(max-device-width: 768px)': {
      fontSize: '14px',
    },
  },
  featureDescr: {
    marginTop: '8px',
    fontSize: '14px',
    lineHeight: 1.43,
    '@media(max-device-width: 768px)': {
      fontSize: '12px',
    },
  },
  text: {
    fontSize: '16px',
    lineHeight: 1.5,
    '@media(max-device-width: 768px)': {
      fontSize: '14px',
    },
  },
  textField: {
    marginTop: '16px',
  },
  buttons: {
    marginTop: '24px',
  },
  button: {
    height: '40px',
    lineHeight: '40px',
  },
});
