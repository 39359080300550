import { AccountType, iWheelDefaultTemplate } from '../../API/interfaces';

export const makeSubtitleText = (accountType: AccountType) => {
  switch (accountType) {
    case AccountType.PERSONAL:
      return 'Templates for life';
    case AccountType.SCHOOL:
      return 'Templates for schools';
    case AccountType.BUSINESS:
      return 'Templates for workplaces';
    default:
      return null;
  }
};

export interface SeparatedTemplates {
  [AccountType.PERSONAL]: Array<iWheelDefaultTemplate>;
  [AccountType.BUSINESS]: Array<iWheelDefaultTemplate>;
  [AccountType.SCHOOL]: Array<iWheelDefaultTemplate>;
  [AccountType.ATHLETIC_WEST]: Array<iWheelDefaultTemplate>;
  [AccountType.SKILLHIRE]: Array<iWheelDefaultTemplate>;
  [AccountType.WUNAN]: Array<iWheelDefaultTemplate>;
  [AccountType.METATTUDE]: Array<iWheelDefaultTemplate>;
}

export const separateTemplates = (defaultTemplates: Array<iWheelDefaultTemplate>): SeparatedTemplates => {
  const personalTemplates: Array<iWheelDefaultTemplate> = [];
  const schoolTemplates: Array<iWheelDefaultTemplate> = [];
  const businessTemplates: Array<iWheelDefaultTemplate> = [];
  const athleticWestTemplates: Array<iWheelDefaultTemplate> = [];
  const skillhireTemplates: Array<iWheelDefaultTemplate> = [];
  const wunanTemplates: Array<iWheelDefaultTemplate> = [];
  const metattudeTemplates: Array<iWheelDefaultTemplate> = [];

  defaultTemplates.forEach((defaultTemplate) => {
    switch (defaultTemplate.accountTag) {
      case AccountType.PERSONAL:
        personalTemplates.push(defaultTemplate);
        break;
      case AccountType.SCHOOL:
        schoolTemplates.push(defaultTemplate);
        break;
      case AccountType.BUSINESS:
        businessTemplates.push(defaultTemplate);
        break;
      case AccountType.ATHLETIC_WEST:
        athleticWestTemplates.push(defaultTemplate);
        break;
      case AccountType.SKILLHIRE:
        skillhireTemplates.push(defaultTemplate);
        break;
      case AccountType.WUNAN:
        wunanTemplates.push(defaultTemplate);
        break;
      case AccountType.METATTUDE:
        metattudeTemplates.push(defaultTemplate);
        break;
      default:
        break;
    }
  });
  return {
    [AccountType.PERSONAL]: personalTemplates,
    [AccountType.BUSINESS]: businessTemplates,
    [AccountType.SCHOOL]: schoolTemplates,
    [AccountType.ATHLETIC_WEST]: athleticWestTemplates,
    [AccountType.SKILLHIRE]: skillhireTemplates,
    [AccountType.WUNAN]: wunanTemplates,
    [AccountType.METATTUDE]: metattudeTemplates,
  };
};
