import { useState } from 'react';
import styled from 'styled-components';
import { Modal, ModalBody } from 'reactstrap';
import { Text } from '../../../Shared/Typography/Typography';
import { makeStyles } from '@material-ui/core';
import { LineLink } from '../../../Shared/LineLink/LineLink';
import { FiX } from 'react-icons/fi';
import { GRADIENTS } from '../../../Shared/colors';
import { useSelector } from 'react-redux';
import { selectIpAddress } from '../../../Auth/Auth.selectors';
import { Button } from '../../../Shared/Buttons/Button';
import { analytics } from '../../../../analytics/analytics';
import PopUpResources from './PopUpResources';

export const PostCheckinPopup = ({ close, segmentScores, setTabActive, title, show = false, user = '' }) => {
  const classes = useStyles();
  const [isAccordionOpened, openAccordion] = useState(false);
  const userIpAddress = useSelector(selectIpAddress);

  const open = () => {
    openAccordion(true);
  };

  const handleButtonClick = (title) => {
    setTabActive(title);
    analytics.openSuggestedResources();
    close();
  };

  const isAustraliaLocation = userIpAddress?.country === 'AU';

  return (
    <Modal isOpen={true} toggle={close} className={classes.dialog}>
      <ModalBody className={classes.dialogBody}>
        <FiX className={classes.closeButton} size={18} onClick={close} />
        <div className="mb-2">
          <Text size="30px" color="#0c2337">
            Check In done!
          </Text>
        </div>
        <div className="mb-3">
          <Text size="18px" color="#2b2e31">
            See your ireflect check in results below
          </Text>
        </div>
        {segmentScores && (
          <div>
            <SegmentsWrapper>
              {segmentScores?.map((seg, i) => (
                <SegmentPill index={i} key={i}>
                  {seg.segmentName} {seg.score}
                </SegmentPill>
              ))}
            </SegmentsWrapper>
          </div>
        )}
        <div className="mt-5 mb-2">
          <Text size="18px" color="#2b2e31">
            <b>What Next?</b>
          </Text>
        </div>
        <div className="mb-2">
          <Text size="16px" color="#2b2e31">
            What to do when having a conversation about your ireflect check in:
          </Text>
        </div>
        <div className="mb-4">
          <Text size="16px" color="#2b2e31">
            1. Share your check in with a trusted person. If you cant connect now, organise a time to catch up with
            someone you trust.
            <br />
            2. When discussing your results, focus on the strengths and discuss things going well.
            <br />
            3. If appropriate, explore ways to bridge the gap in the low scores and discuss the goals you want to set.
            <br />
            4. You can always invite your trusted person to be your accountability buddy, to support you along your
            check in journey.
          </Text>
        </div>
        {!show && <PopUpResources segmentScores={segmentScores} />}
        {!show && user && <LineLink link="/support?type=0">Other Resources</LineLink>}
        <AreYouOkWrapper>
          <div className="mb-3">
            <Text color="#0c2337" size="18px" weight={500}>
              Are you okay?
            </Text>
          </div>
          <div className="mb-2">
            <Text size="14px" lineHeight="1.4" color="#6a777f">
              A reminder that while ireflect is a great way to check in on yourself and peers, it is important that if you
              are really not ok, you take the actions to seek support from loved ones or professionals.
            </Text>
          </div>
          <div className="mb-2">
            <Text size="14px" lineHeight="1.4" color="#6a777f">
              If you really are not ok, go and talk to a trusted friend, family member or peer worker. We encourage you
              to book in an appointment with a professional your local GP or psychologist if you're consistently feeling
              low.
            </Text>
          </div>
          {isAustraliaLocation && (
            <div className="mb-2">
              <Text size="14px" lineHeight="1.4" color="#6a777f">
                Access to publicly available services in Australia can be connected to directly via our crisis support
                links below.
              </Text>
            </div>
          )}
        </AreYouOkWrapper>
        {isAustraliaLocation && <LineLink link="/support?type=2">Crisis Support Link</LineLink>}
        {show && (
          <ButtonWrapper className="mt-4">
            <Button fontSize="14px" height="56px" onClick={() => handleButtonClick(title)}>
              CHECK SUGGESTED RESOURCES
            </Button>
          </ButtonWrapper>
        )}
      </ModalBody>
    </Modal>
  );
};

const useStyles = makeStyles({
  dialog: {
    // width: '1000px',
    maxWidth: '1000px',
    '& .modal-content': {
      border: 'none',
    },
  },
  dialogBody: {
    width: '100%',
    padding: '40px',
    '@media (max-width: 768px)': {
      padding: '24px',
    },
  },
  closeButton: {
    position: 'absolute',
    top: '30px',
    right: '30px',
    cursor: 'pointer',
  },
});

const AreYouOkWrapper = styled.div`
  width: 920px;
  max-width: 100%;
  margin: 24px 8px 0 0;
  padding: 24px;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.07);
  background-color: #fff;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const ButtonWrapper = styled.div`
  width: 920px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const SegmentsWrapper = styled.div`
  display: flex;
  width: 920px;
  gap: 8px;
  justify-content: flex-start;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SegmentPill = styled.div<any>`
  position: relative;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 12px;
  border-radius: 16px;
  color: #ffffff;
  background-image: ${(props) => {
    if (props.index !== null) {
      const gradientIndex = props.index % GRADIENTS.length;
      return `linear-gradient(116deg, ${GRADIENTS[gradientIndex][0]}, ${GRADIENTS[gradientIndex][1]} 87%)`;
    }
  }};
`;

const PostWrapper = styled.div`
  display: flex;
  width: 920px;
  padding: 8px 8px 0px;
  justify-content: flex-start;
  flex-wrap: wrap;
  background-color: #ffffff;
  border-radius: 8px 8px 0px 0px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const PostAccordion = styled.div<any>`
  max-height: ${(props) => (props.opened ? '1000px' : '280px')};
  overflow: hidden;
  transition: all 0.3s ease;
  @media (max-width: 768px) {
    max-height: ${(props) => (props.opened ? 'none' : '280px')};
  }
`;

const Post = styled.div<any>`
  flex: 0 0 296px;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position: center center;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  width: 296px;
  height: 200px;
  margin-bottom: 8px;
  margin-right: 8px;
  &:nth-child(3n) {
    margin-right: 0;
  }
  @media (max-width: 768px) {
    flex: 0 0 100%;
  }
  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

const PostPill = styled.div<any>`
  position: absolute;
  top: 16px;
  left: 16px;
  padding: 2px 8px;
  border-radius: 16px;
  color: #ffffff;
  background-image: ${(props) => {
    if (props.index !== null) {
      const gradientIndex = props.index % GRADIENTS.length;
      return `linear-gradient(116deg, ${GRADIENTS[gradientIndex][0]}, ${GRADIENTS[gradientIndex][1]} 87%)`;
    }
  }};
`;

const PostInfo = styled.div<any>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.75) 25%,
    rgba(0, 0, 0, 0) 75%,
    rgba(0, 0, 0, 0) 100%
  );
  padding: 16px;
`;
