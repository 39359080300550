import React from 'react';
import { WheelMemberRoleType, WheelRoleNaming } from '../../API/interfaces';
import { Button, CancelButton } from '../Shared/Buttons/Button';
import { Spinner } from '../Shared/Spinner/Spinner';

interface iBrowserStepProps {
  wheelName: string;
  invitingMemberName: string;
  onCancel: () => void;
  onSubmit: () => void;
  inviteRole: WheelMemberRoleType;
}

export default ({ wheelName, invitingMemberName, onCancel, onSubmit, inviteRole }: iBrowserStepProps) =>
  wheelName ? (
    <div>
      <div className="row mb-px-3">
        <div className="col-12 text-center text-lg-left">
          {invitingMemberName} has invited you to join their Wheel “{wheelName}” as {WheelRoleNaming[inviteRole]}.
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center text-lg-right">
          <CancelButton onClick={onCancel} className="mr-2">
            CANCEL
          </CancelButton>
          <Button onClick={onSubmit}>JOIN</Button>
        </div>
      </div>
    </div>
  ) : (
    <div className="d-flex justify-content-center">
      <Spinner />
    </div>
  );
