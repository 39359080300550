import React, { useState } from 'react';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import './TextInput.scss';

interface iTextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  className?: string;
  placeholder?: string;
  label?: string;
  type?: string;
  error?: string;
  touched?: boolean;
}

export const TextInput = (props: iTextInputProps) => {
  const { id = '', className = '', label = '', error = '', touched = false, type = 'text' } = props;
  const [typeState, setTypeState] = useState(type);

  const passwordIconShow = <BsFillEyeFill onClick={() => setTypeState('text')} />;
  const passwordIconHide = <BsFillEyeSlashFill onClick={() => setTypeState('password')} />;

  return (
    <div className={`ireflect-input ${className} ${touched && error && 'error'}`}>
      <label htmlFor={id} className="ireflect-input_label">
        {error && touched ? error : label}
      </label>
      <div className="ireflect-input_field-container">
        <input {...props} type={typeState} className="ireflect-input_field" />
        {type === 'password' && (
          <div className="ireflect-input_addon">{typeState === 'password' ? passwordIconShow : passwordIconHide}</div>
        )}
      </div>
    </div>
  );
};
