import React from 'react';

interface iStepCounterProps {
  currentStep: number;
  amountOfSegments: number;
}

export default ({ currentStep, amountOfSegments }: iStepCounterProps) => (
  <div className="step-counter">
    <span className="current-step">
      {`${currentStep === amountOfSegments ? 'Your result' : `Step ${currentStep + 1}`}`}
    </span>
    {currentStep < amountOfSegments && <span>{`/${amountOfSegments + 1}`}</span>}
  </div>
);
