import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { countSession } from '../../Redux/Actions/Actions';
import SupportButton from '../Support/SupportButton';
import CustomBranding from '../../Assets/images/features/custom-branding.png';
import MultiAdminImage from '../../Assets/images/features/multiple-admin.png';
import TeamAlertsImage from '../../Assets/images/features/team-alerts.png';
import CustomisationImage from '../../Assets/images/features/customisation.png';
import SupportImage from '../../Assets/images/features/support.png';
import './App.scss';

interface iAppProps extends RouteComponentProps {
  countSession: () => void;
}

interface iAppState {
  hasError: boolean;
}

class App extends Component<iAppProps, iAppState> {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  shouldShowHelpBtn = (): boolean => {
    const {
      location: { pathname },
    } = this.props;

    return !['/onboarding', '/wheel-member-image', '/wheel-image', '/wheel-report'].includes(pathname);
  };

  changePageTitle = async () => {
    const subDomain = window.location.hostname.split('.')[0];
    if (['dev', 'staging', 'localhost'].includes(subDomain)) {
      document.title = `ireflect - (${subDomain})`;
    }
  };

  // experimental
  preloadImages = async () => {
    [MultiAdminImage, CustomBranding, CustomisationImage, TeamAlertsImage, SupportImage].forEach(async (href) => {
      const preloadLink = document.createElement('link');
      preloadLink.href = href;
      preloadLink.rel = 'prefetch';
      preloadLink.as = 'image';
      document.head.appendChild(preloadLink);
    });
  };

  bodyOnClick = (e) => {
    if (!e) return;

    const t: any = e.target;

    if (!t) return;

    if (t.id === 'login-user-avatar' || (t.parentNode && t.parentNode.id === 'login-user-avatar')) return;

    const dm: any = document.querySelector('#context-menu');

    if (dm && dm.style.display === 'flex') dm.style.display = 'none';
  };

  async componentDidMount() {
    const body = document.querySelector('body');

    // TODO REMOVE THIS PIECE OF CODE!
    if (body) {
      body.onclick = this.bodyOnClick;
    }

    this.props.countSession();
    await this.changePageTitle();
    await this.preloadImages();
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.

    console.log('=+'.repeat(40));
    console.error(error.message);
    console.log('=+'.repeat(40));

    return { hasError: true };
  }

  componentDidCatch(err, info) {
    console.log(err.message);
    console.log(info, info.componentStack);
  }

  render() {
    const { hasError } = this.state;

    return hasError ? (
      <div id="app">
        <div className="text-center">Some errors have happend!</div>
        <div className="text-center">
          Go to{' '}
          <a
            href="/"
            onClick={() => {
              window.location.href = '/';
            }}
          >
            Main page
          </a>
        </div>

        <SupportButton />
      </div>
    ) : (
      <div id="app">
        {this.props.children}

        {this.shouldShowHelpBtn() && <SupportButton />}
      </div>
    );
  }
}

export default withRouter(connect(null, { countSession })(App));
