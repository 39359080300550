import React from 'react';
import styled, { css } from 'styled-components';

import { iWheelExt, WheelMemberRoleType, WheelMemberRole, WheelRoleNaming } from '../../../../API/interfaces';
import { WHEEL_ACCESS_TYPES } from '../../../../constants';

import './LabelSection.scss';
import UsersIcon from './UsersIcon';
import UserIcon from './UserIcon';
import UserCheckIcon from './UserCheckIcon';
import UserPlusIcon from './UserPlusIcon';
import { FiLifeBuoy } from 'react-icons/fi';

interface iAccessLabelProps {
  wheel: iWheelExt;
}

export const AccessLabel = ({ wheel }: iAccessLabelProps) => {
  if (wheel.isTemplate) {
    return <Wrapper2>Template</Wrapper2>;
  } else if (wheel.access === WHEEL_ACCESS_TYPES.PUBLIC) {
    return (
      <Wrapper>
        <StyledUsersIcon />
        Public
      </Wrapper>
    );
  } else if (wheel.access === WHEEL_ACCESS_TYPES.PRIVATE) {
    return (
      <Wrapper1>
        <StyledUserIcon />
        Private
      </Wrapper1>
    );
  }
  return null;
};

export const OwnershipLabel = ({ wheelRole }: { wheelRole: WheelMemberRoleType }) => {
  if (wheelRole === WheelMemberRole.ADMIN || wheelRole === WheelMemberRole.ACCOUNTABILITY_BUDDY) {
    return (
      <Wrapper1>
        {wheelRole === WheelMemberRole.ADMIN ? (
          <StyledUserCheckIcon />
        ) : (
          <StyledAccountabilityBuddyIcon size="12px" color="#fff" />
        )}
        I am {WheelRoleNaming[wheelRole].toLocaleLowerCase()}
      </Wrapper1>
    );
  }
  return (
    <Wrapper>
      <StyledUserPlusIcon />I am member
    </Wrapper>
  );
};

const Wrapper = styled.div<{ isAdmin?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 12px;
  font-size: 0.8em;
  color: white;
  border-radius: 20px;
  background: #ffe2c1;
  color: #0c2337;
  & + & {
    margin-left: 8px;
  }
`;

const Wrapper1 = styled(Wrapper)`
  background: #2a3e50;
  color: #fff;
`;

const Wrapper2 = styled(Wrapper)`
  background: #ff4e4b;
`;

const iconMargin = css`
  margin-right: 4px;
`;
const StyledUserIcon = styled(UserIcon)`
  ${iconMargin};
`;
const StyledUsersIcon = styled(UsersIcon)`
  ${iconMargin};
`;
const StyledUserCheckIcon = styled(UserCheckIcon)`
  ${iconMargin};
`;
const StyledUserPlusIcon = styled(UserPlusIcon)`
  ${iconMargin};
`;
const StyledAccountabilityBuddyIcon = styled(FiLifeBuoy)`
  ${iconMargin};
`;
