import React, { useEffect, useState } from 'react';
import { AuthContainer } from '../AuthContainer';
import './SignUpForm.scss';
import { Checkbox } from '../../Shared/Inputs/Checkbox';
import { TextInput } from '../../Shared/Inputs/TextInput';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import services from '../../../API/services';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button } from '../../Shared/Buttons/Button';
import { Toast } from '../../Shared/Toaster/Toast';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Text, Title } from '../../Shared/Typography/Typography';
import { analytics } from '../../../analytics/analytics';
import { ToastTypes } from '../../Shared/Toaster/Toaster.actions';
import { authService } from '../Auth.service';
import { SignInButtonMS } from '../SSO/msal/SignInButtonMS';
import { Divider, DividerContent } from '../SSO/Divider';
import { AccountType } from '../../../API/interfaces';
import { resetInvitationLink } from '../../Invitations/invitation.actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectInvitationLink } from '../../Invitations/invitation.selectors';

type iSignUpProps = RouteComponentProps;

const initialValues = {
  policyChecked: false,
  firstName: '',
  lastName: '',
  email: '',
  password: '',
};

const validationSchema = yup.object({
  email: yup.string().trim().email('Not valid email format').required('The field can’t be empty'),
  password: yup
    .string()
    .trim()
    .min(6, 'Password must contain at least 6 characters')
    .required('The field can’t be empty'),
  firstName: yup.string().trim().max(50, 'The value of the field is too long').required('The field can’t be empty'),
  lastName: yup.string().trim().max(50, 'The value of the field is too long').required('The field can’t be empty'),
  policyChecked: yup
    .boolean()
    .required('The field can’t be empty')
    .oneOf([true], 'Please, agree with Terms&Conditions and Privacy Policy'),
});

const SignUpComponent = ({ history, location }: iSignUpProps) => {
  const [serverError, setServerError] = useState('');
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const tagParam = new URLSearchParams(location.search).get('tag') as AccountType | null;
  const accountTag: AccountType = tagParam ? tagParam : AccountType.PERSONAL_BUSINESS;
  const invitationLink = useSelector(selectInvitationLink);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!tagParam) {
      analytics.onboardingCustomEvent();
    }
  }, []);

  const next = () => {
    if (!tagParam) {
      history.push('/onboarding');
    } else {
      if (invitationLink) {
        dispatch(resetInvitationLink());
        history.push(invitationLink);
      } else {
        history.push('/dashboard');
      }
    }
    // setModal(false);
  };

  const login = async ({ email, password }) => {
    const loginResponse = await services.userLogin({
      email,
      password,
    });
    if (loginResponse.status === 200) {
      authService.login({
        token: loginResponse.data.access_token,
        ...loginResponse.data.user,
      });
      next();
      // setModal(true);
    } else {
      setServerError(loginResponse.data.message);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    if (formik.isValid && formik.dirty && !loading) {
      const response = await services.userSignUp({
        accountTag,
        ...values,
      });
      if (response.status === 200) {
        await login(values);
        analytics.signUp();
      } else {
        setServerError(response.data.message);
      }
      setLoading(false);
    }
  };

  const loginSSO = async (response) => {
    if (response.status === 200) {
      analytics.signUpSSO();
      authService.login({
        token: response.data.access_token,
        ...response.data.user,
      });
      setModal(true);
    } else {
      setServerError(response.data.message);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const loginLinkEvent = () => {
    analytics.loginLink();
  };

  const SuccessModal = (
    <div>
      <Modal isOpen={modal} toggle={next}>
        <ModalHeader toggle={next}>Welcome to ireflect!</ModalHeader>
        <ModalBody>
          <Text>Check your email {formik.values.email} for tips to make the most of ireflect.</Text>
        </ModalBody>
        <ModalFooter>
          <Button onClick={next}>Ok</Button>
        </ModalFooter>
      </Modal>
    </div>
  );

  const buildSignUpLink = () => {
    switch (tagParam) {
      case AccountType.ATHLETIC_WEST:
        return '/login?tag=athletic_west';
      case AccountType.SKILLHIRE:
        return '/login?tag=skillhire';
      case AccountType.WUNAN:
        return '/login?tag=wunan';
      case AccountType.METATTUDE:
        return '/login?tag=metattude';
      default:
        return '/login';
    }
  };

  return (
    <AuthContainer>
      <div className="signup">
        {serverError && (
          <div className="mb-4">
            <Toast type={ToastTypes.ERROR} message={serverError} />
          </div>
        )}
        <Title>Sign Up</Title>
        <div className="mb-4">
          <Text>
            Already have an account?{' '}
            <Link className="signup_login-link" to={buildSignUpLink()} onClick={loginLinkEvent}>
              Log In
            </Link>
          </Text>
        </div>
        {tagParam !== AccountType.SKILLHIRE &&
          tagParam !== AccountType.ATHLETIC_WEST &&
          tagParam !== AccountType.WUNAN &&
          tagParam !== AccountType.METATTUDE && (
            <>
              <div className="mb-3">
                <SignInButtonMS submitSSO={loginSSO}>Sign Up with Microsoft</SignInButtonMS>
              </div>
              <div>
                <Divider>
                  <DividerContent>or</DividerContent>
                </Divider>
              </div>
            </>
          )}
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-4 d-flex">
            <div className="mr-3">
              <TextInput
                id="firstName"
                name="firstName"
                label="First Name"
                placeholder="Enter Your First Name"
                value={formik.values.firstName}
                onChange={formik.handleChange('firstName')}
                onBlur={formik.handleBlur('firstName')}
                error={formik.errors.firstName}
                touched={formik.touched.firstName}
              />
            </div>
            <div>
              <TextInput
                id="lastName"
                name="lastName"
                label="Last Name"
                placeholder="Enter Your Last Name"
                value={formik.values.lastName}
                onChange={formik.handleChange('lastName')}
                onBlur={formik.handleBlur('lastName')}
                error={formik.errors.lastName}
                touched={formik.touched.lastName}
              />
            </div>
          </div>
          <div className="mb-4">
            <TextInput
              id="email"
              name="email"
              label="Email"
              type="email"
              placeholder="Enter Your Email"
              value={formik.values.email}
              onChange={formik.handleChange('email')}
              onBlur={formik.handleBlur('email')}
              error={formik.errors.email}
              touched={formik.touched.email}
            />
          </div>
          <div className="mb-4">
            <TextInput
              id="password"
              label="Password"
              name="password"
              placeholder="Enter Your Password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange('password')}
              onBlur={formik.handleBlur('password')}
              error={formik.errors.password}
              touched={formik.touched.password}
            />
          </div>
          <div className="d-flex mb-4">
            <div className="mr-2">
              <Checkbox
                id="policyChecked"
                className="signup_checkbox"
                name="policyChecked"
                checked={formik.values.policyChecked}
                onChange={formik.handleChange('policyChecked')}
                onBlur={formik.handleBlur('policyChecked')}
                error={formik.errors.policyChecked}
                touched={formik.touched.policyChecked}
              >
                <span className="signup_policy">
                  I have read and accept the{' '}
                  <a
                    target="_blank"
                    className="signup_policy-link"
                    href="https://ireflect.com.au/ireflect-terms-and-conditions/"
                    rel="noreferrer"
                  >
                    Terms & Conditions
                  </a>{' '}
                  and{' '}
                  <a
                    target="_blank"
                    className="signup_policy-link"
                    href="https://ireflect.com.au/privacy-policy/"
                    rel="noreferrer"
                  >
                    Privacy & Policy
                  </a>
                </span>
              </Checkbox>
            </div>
          </div>
          <div>
            <Button
              className="iyarn-button-new text-uppercase"
              disabled={!(formik.isValid && formik.dirty)}
              type="submit"
            >
              Sign Up
            </Button>
          </div>
        </form>
      </div>
      {SuccessModal}
    </AuthContainer>
  );
};

export const SignUpForm = withRouter(SignUpComponent);
