import React, { useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import SegmentTags from '../../common/SegmentTags/SegmentTags';
import { uniqBy } from 'lodash';
import styled from 'styled-components';
import { SupportClient } from '../../../../API/support.client';
import { Text } from '../../../Shared/Typography/Typography';
import { Spinner, SpinnerBackground } from '../../../Shared/Spinner/Spinner';
import { shuffle } from 'd3';
import { GRADIENTS } from '../../../Shared/colors';
import { tagsList } from '../../Tabs/SuggestedResources/TagList';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { ChevronDown } from 'react-feather';
import './PopUpResources.scss';

interface iResourcesProps extends RouteComponentProps {
  segmentScores: any;
}

interface iResourcesState {
  activeSegmentId: string | null;
}

const PopUpResources: React.FC<iResourcesProps> = ({ segmentScores }) => {
  const [state, setState] = useState<iResourcesState>({
    activeSegmentId: null,
  });

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [segmentPills, setSegmentPills] = useState([]);

  const { activeSegmentId } = state;

  const setActiveSegmentId = (segmentId) => {
    setState((prevState) => ({ ...prevState, activeSegmentId: segmentId }));
  };

  useEffect(() => {
    if (segmentScores) {
      fetchPosts();
    }

    async function fetchPosts() {
      setLoading(true);
      await fetchPostsByTags();
      setLoading(false);
    }
  }, [segmentScores]);

  const fetchPostsByTags = async () => {
    const segmentNames = segmentScores.map(({ segmentName }) =>
      segmentName.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase())
    );
    setSegmentPills(segmentNames);

    const segmentIds = segmentScores.reduce(
      (acc, { segmentId }) => ({
        ...acc,
        [segmentId]: [],
      }),
      {}
    );

    const relevantTags = tagsList
      .map(({ title, tag }) => ({
        title: title.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase()),
        tag,
      }))
      .filter(({ title }) => segmentNames.includes(title))
      .map((item) => ({
        ...item,
        index: segmentNames.indexOf(item.title),
      }))
      .sort((a, b) => a.index - b.index);

    try {
      const fetchedData = await Promise.all(relevantTags.map(({ tag }) => SupportClient.getResourcesByTags(tag, 100)));

      fetchedData.forEach((data, index) => {
        const parsedData = data.map((article) => ({
          ...SupportClient.parseArticles(article),
          tag: relevantTags[index].title,
          link: article.link,
        }));
        segmentIds[Object.keys(segmentIds)[relevantTags[index].index]] = parsedData;
      });
      setData(segmentIds);
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };

  const tagPosts: any[] = useMemo(() => {
    const shuffledPosts = shuffle(
      activeSegmentId === null ? uniqBy(Object.values(data).flat(), 'id') : data[activeSegmentId]
    );

    const firstSixItems = shuffledPosts.slice(0, 6);
    const restItems = shuffledPosts.slice(6);

    return [firstSixItems, restItems];
  }, [activeSegmentId, data]);

  const scoresSum = segmentScores.reduce((acc, obj) => acc + obj.score, 0);
  const averageScore = parseFloat((scoresSum / segmentScores.length).toFixed(1));

  const renderPosts = (posts) => {
    return posts.map((p) => {
      const segmentIndex = segmentPills?.indexOf(p?.tag);
      return (
        <Post key={p.id} image={p.image} href={p.link} target="_blank">
          <SegmentPill index={segmentIndex}>{p.tag}</SegmentPill>
          <PostInfo>
            <Text size="14px" color="#ffffff" lineHeight={1.33} weight={500} className="mb-2">
              {p.title}
            </Text>
            <Text size="12px" color="#ffffff" weight={300}>
              {p.date}
            </Text>
          </PostInfo>
        </Post>
      );
    });
  };

  return (
    <div className="public-chekin-resources">
      <Accordion onChange={(_, expanded: boolean) => !expanded}>
        <AccordionSummary expandIcon={<ChevronDown size={16} />}>
          <ModalWrapper>
            <b>View some of ireflect's suggested resources</b>
            <div className="resources">
              {segmentScores && (
                <SegmentTags
                  averageScore={averageScore}
                  scores={segmentScores}
                  activeSegmentId={activeSegmentId}
                  onClick={setActiveSegmentId}
                />
              )}
            </div>
            <PostsWrapper>
              {loading ? (
                <SpinnerBackground height="50vh">
                  <Spinner />
                </SpinnerBackground>
              ) : !tagPosts[0].length ? (
                <Message>There are no resources associated with this segment!</Message>
              ) : (
                renderPosts(tagPosts[0])
              )}
            </PostsWrapper>
          </ModalWrapper>
        </AccordionSummary>
        <AccordionDetails className="d-block">
          <PostsWrapper>{renderPosts(tagPosts[1])}</PostsWrapper>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default withRouter(PopUpResources);

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PostsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Message = styled.div`
  width: 100%;
  text-align: center;
`;

const Post = styled.a<any>`
  //   flex: 0 0 calc(33.3% - 15px);
  display: flex;
  width: calc(33% - 4px);
  aspect-ratio: 3 / 2;
  margin-bottom: 8px;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position: center center;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  @media (max-width: 768px) {
    flex: 0 0 100%;
  }
  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

const PostInfo = styled.div<any>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.75) 25%,
    rgba(0, 0, 0, 0) 75%,
    rgba(0, 0, 0, 0) 100%
  );
  padding: 24px;
`;

const TabWrapper = styled.div`
  padding: 50px;
  position: relative;
  width: 100%;
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SegmentPill = styled.div<any>`
  position: absolute;
  top: 24px;
  left: 24px;
  font-size: 12px;
  line-height: 20px;
  padding: 2px 8px;
  border-radius: 16px;
  color: #ffffff;
  background-image: ${(props) => {
    if (props.index !== null) {
      const gradientIndex = props.index % GRADIENTS.length;
      return `linear-gradient(116deg, ${GRADIENTS[gradientIndex][0]}, ${GRADIENTS[gradientIndex][1]} 87%)`;
    }
  }};
`;
