import React, { useState } from 'react';
import { iWheelMember, WheelMemberRole, WheelMemberRoleType } from '../../../../API/interfaces';
import styled from 'styled-components';
import { Text } from '../../../Shared/Typography/Typography';
import { RadioButton } from '../../../Shared/RadioButton';
import { customConfirm } from '../../../Shared/ConfirmModal/ConfirmationModal';
import { FiTrash2 } from 'react-icons/fi';
import { Button, Popover, PopoverBody } from 'reactstrap';
import { BsThreeDotsVertical } from 'react-icons/bs';
import utils from '../../../Shared/utils';
import { Section } from '../../../Shared/Layout/Section';
import { InitialsCircle } from '../../../Shared/InitialsCircle/InitialsCircle';
import { FiUser } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../Auth/Auth.selectors';
import MemberDropdown from '../MemberResults/MemberDropdown/MemberDropdown';
import CheckInAssistIcon from '../../../../Assets/images/checkin-assist.png';
import ExpandButton from '../../../Shared/ExpandButton';
import { AssistMember } from '../../WheelPage';
import { analytics } from '../../../../analytics/analytics';

interface iWheelMemberProps {
  member: iWheelMember;
  wheelName: string;
  adminsNumber?: number | null;
  onChangeRole: (userId: string, newRole: WheelMemberRole) => void;
  onDeleteMember: (userId: string) => void;
  allWheelMembers?: iWheelMember[];
  wheelRole?: WheelMemberRoleType;
  isAdminList: boolean;
  openCheckinAssistModal?: (member: AssistMember) => void;
}

const Rectangle = styled.div`
  width: 100%;
  height: 60px;
  padding: 15px;
  border-radius: 2px;
  border: solid 1px #cdd4da;
  border-bottom: none;
  background-color: #ffffff;
  display: flex;
  align-items: center;

  &:last-child {
    border-bottom: solid 1px #cdd4da;
  }

  @media (max-width: 1024px) {
    padding: 10px;
  }
`;

const Delete = styled.div`
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    cursor: pointer;
  }

  @media (max-width: 1024px) {
    padding: 0;
    svg {
      margin-right: 10px;
    }
  }
`;

const RoleControls = styled.div`
  display: flex;
  width: 200px;
  justify-content: space-between;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const BuddyMemberContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #cdd4da;
  border-radius: 16px;
  padding: 4px 8px 4px 4px;
`;

const UserIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #cdd4da;
  height: 16px;
  width: 16px;
  margin-right: 4px;
`;

const ExpandButtonContainer = styled.div`
  margin-left: 24px;
`;

export const WheelMember: React.FC<iWheelMemberProps> = ({
  member,
  wheelName,
  adminsNumber = null,
  onChangeRole,
  onDeleteMember,
  allWheelMembers,
  wheelRole,
  openCheckinAssistModal,
}) => {
  const { memberRole, firstName, lastName, userId, accountabilityBuddyFor } = member;
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);
  const user = useSelector(selectUser);
  const memberFullName = `${firstName} ${lastName}`;
  const isAccountabilityBuddyList = memberRole === WheelMemberRole.ACCOUNTABILITY_BUDDY;
  const isAdminList = memberRole === WheelMemberRole.ADMIN;
  const isMemberList = memberRole === WheelMemberRole.MEMBER;
  const isWheelAdmin = wheelRole === WheelMemberRole.ADMIN;
  const showCheckInAssistButton = isMemberList && isWheelAdmin;

  const changeRoleToAdmin = async (value: WheelMemberRole) => {
    const answer = await customConfirm({
      title: `Are you sure that you want to assign admin rights to ${memberFullName}?`,
      body: ` As an admin, access will be granted to: Wheel settings, Team results, Individual member results, User management.`,
      confirmationText: 'CHANGE ROLE',
    });
    if (answer) {
      onChangeRole(userId, value);
    }
  };

  const changeRoleToMember = async (value: WheelMemberRole) => {
    const answer = await customConfirm({
      title: `Are you sure that you want to change ${memberFullName} to a regular member?`,
      body: 'Admin permissions will be removed.',
      confirmationText: 'CHANGE ROLE',
    });
    if (answer) {
      onChangeRole(userId, value);
    }
  };

  const deleteMember = async () => {
    const title = isAccountabilityBuddyList
      ? `Remove ${memberFullName} as accountability buddy?`
      : `Delete ${memberFullName}?`;
    const body = isAccountabilityBuddyList
      ? `Are you sure you want to remove ${memberFullName} as an accountability buddy for ${wheelName}?`
      : 'All data will be deleted. You will not be able to restore the data of this member.';
    const confirmationText = isAccountabilityBuddyList ? 'REMOVE' : 'DELETE';
    const answer = await customConfirm({
      title,
      body,
      confirmationText,
    });
    if (answer) {
      onDeleteMember(userId);
    }
  };

  const isMobile = utils.checkIsPhone();

  const onCheckinAssist = () => {
    openCheckinAssistModal({
      id: userId,
      name: memberFullName,
    });
    analytics.openMembersCheckinAssist();
  };

  const accountabilityBuddyForMembersList =
    allWheelMembers && accountabilityBuddyFor?.length
      ? accountabilityBuddyFor.map((userForABId: string) => {
          const accountabilityBuddyForMember = allWheelMembers.find((member) => userForABId === member.userId);
          return (
            accountabilityBuddyForMember && {
              ...accountabilityBuddyForMember,
              fullName: `${accountabilityBuddyForMember.firstName} ${accountabilityBuddyForMember.lastName}`,
            }
          );
        })
      : [];

  const isMyBuddy = member.accountabilityBuddyFor.includes(user._id);
  const membersDropdownInputText = `${accountabilityBuddyFor.length} members`;

  const AccountabilityBuddyControls = (
    <>
      {accountabilityBuddyForMembersList.length === 1 ? (
        <BuddyMemberContainer>
          <UserIconContainer>
            <FiUser color="#fff" size="8px" />
          </UserIconContainer>
          <Text color="#2b2e31" size="12px">
            {accountabilityBuddyForMembersList[0].fullName}
          </Text>
        </BuddyMemberContainer>
      ) : (
        <>
          {!!accountabilityBuddyForMembersList.length && (
            <MemberDropdown
              isDisabledAction
              className="education_member-dropdown"
              members={accountabilityBuddyForMembersList}
              inputText={membersDropdownInputText}
            />
          )}
        </>
      )}
    </>
  );

  const Controls = (
    <>
      <div className="d-lg-none d-block"></div>
      {wheelRole !== WheelMemberRole.MEMBER && (
        <>
          {isAccountabilityBuddyList ? (
            <>{AccountabilityBuddyControls}</>
          ) : (
            <RoleControls>
              <RadioButton
                value={WheelMemberRole.MEMBER}
                selected={memberRole}
                text="Member"
                disabled={adminsNumber && adminsNumber < 2}
                onChange={changeRoleToMember}
                tooltipText={`All wheels require at least one admin. Please add another admin.`}
              />
              <RadioButton
                value={WheelMemberRole.ADMIN}
                selected={memberRole}
                text="Admin"
                onChange={changeRoleToAdmin}
              />
            </RoleControls>
          )}
        </>
      )}
      <Section width="70px" mWidth="100%">
        {!isAdminList && (
          <div className="d-flex justify-content-start align-items-center mt-lg-0 mt-1">
            <Delete onClick={deleteMember}>
              <FiTrash2 color="#ff483c" size={isMobile ? 16 : 20} />
              <div className="d-lg-none d-inline">
                <Text color="#ff483c" weight={400} size="14px">
                  Delete Member
                </Text>
              </div>
            </Delete>
          </div>
        )}
      </Section>
    </>
  );

  return (
    <>
      <Rectangle key={userId}>
        <Section width="70%">
          <InitialsCircle className="mr-3">{`${firstName[0]}${lastName[0]}`}</InitialsCircle>
          <Text weight={500} mSize="12px">
            {memberFullName}
          </Text>
          {isMyBuddy && (
            <Text color="#005ebd" size="12px" margin="0 4px">
              your buddy
            </Text>
          )}
          {showCheckInAssistButton && (
            <ExpandButtonContainer>
              <ExpandButton Icon={CheckInAssistIcon} label="Check in assist" width={105} onClick={onCheckinAssist} />
            </ExpandButtonContainer>
          )}
        </Section>
        <Section width="30%" className="d-flex justify-content-end">
          <div className="d-none d-lg-flex">{Controls}</div>
          <div className="d-flex d-lg-none">
            <div className="d-lg-none d-flex justify-content-end">
              <Button id={`Popover_${userId}`} type="button" color="link">
                <BsThreeDotsVertical color="#000000" />
              </Button>
              <Popover
                placement="left-start"
                isOpen={popoverOpen}
                target={`Popover_${userId}`}
                toggle={toggle}
                trigger="legacy"
              >
                <PopoverBody>{Controls}</PopoverBody>
              </Popover>
            </div>
          </div>
        </Section>
      </Rectangle>
    </>
  );
};
