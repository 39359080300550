export const MAX_COMMENT_LENGTH = 1500;

export const MAX_WHEEL_NAME_LENGTH = 40;

export const MAX_DESCRIPTION_LENGTH = 300;

export const MAX_SEGMENT_NAME_LENGTH = 40;

export const MAX_FOLDER_NAME_LENGTH = 40;

export const MIN_SEGMENTS = 3;

export const MAX_SEGMENTS = 13;

export const MAX_SCORE_COMMENT_LENGTH = 500;

export const MIN_WHEEL_SCALE = 2;

export const MAX_WHEEL_SCALE = 100;

export const DEFAULT_WHEEL_SCALE = 10;

export const TIMELINE_CHECKINS_LIMIT = 26;

export enum WHEEL_ACCESS_TYPES {
  PRIVATE = 'private',
  PUBLIC = 'public',
}

export enum WHEEL_STEP_TYPES {
  INTEGER = 'integer',
  NUMBER = 'number', // decimals
}

export enum APP_PLATFORMS {
  IOS = 'ios',
  ANDROID = 'android',
}

export const APP_STORE_URL = 'https://apps.apple.com/ua/app/ireflect/id1518207052';
export const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.ireflect.ireflectmobile';

export const TUTORIAL_VIDEO_URL = 'https://www.youtube.com/embed/yw-GzIL_rzU?list=PL97y7SjjdKwKtGa3FdIOyNQ7Gr6hBU1Ya';

export const LANDING_PAGE_URL = 'https://ireflect.com';

export const MIXPANEL_ID = '370db5cf24d88a0c9941becd76bb9d29';

export const CALENDAR_DATE_FORMAT = 'dd LLL yyyy'; // 25 Feb 2020

export const PDF_DATE_FORMAT = CALENDAR_DATE_FORMAT;

export const SINGLE_DATE_FORMAT = 'dd LLL yy';

export const DATE_RANGE_FORMAT = SINGLE_DATE_FORMAT;

export const SINGLE_DATETIME_FORMAT = 'dd LLL yy HH:mm:ss';

export const COMMENT_DATETIME_FORMAT = "dd LLL yyyy 'at' hh:mm a";

//TODO remove all things attached to window
declare global {
  interface Window {
    store: any;
    recaptchaOptions: any;
    persistor: any;
  }
}

window.store = window.store || {};
