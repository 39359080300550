import React from 'react';
import { ChevronDown } from 'react-feather';
import { makeStyles, Typography, withStyles } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { iWheelDefaultTemplate, iWheelTemplate } from '../../../API/interfaces';
import { MediumButton } from '../../Shared/Buttons/Button';
import SegmentTag from '../../Shared/SegmentTags/SegmentTag/SegmentTag';
import { WHEEL_ACCESS_TYPES } from '../../../constants';
import TemplateContextMenu from './TemplateContextMenu';
import AccountLogoBasePic from '../../../Assets/images/templates/custom_account_template.png';

interface iWheelTemplateProps {
  expandId: string;
  template: iWheelDefaultTemplate | iWheelTemplate;
  setTemplateToUse: (templateToUse: iWheelDefaultTemplate | iWheelTemplate) => void;
  setTemplateToDelete?: (templateToDelete: iWheelTemplate) => void;
  isTemplateAdmin: boolean;
  expanded: boolean;
  onExpand: (expandId: string) => void;
  accountLogo?: string;
}

export default ({
  template,
  setTemplateToUse,
  setTemplateToDelete,
  isTemplateAdmin,
  expanded,
  onExpand,
  expandId,
  accountLogo,
}: iWheelTemplateProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.summary}>
        <div className={classes.description}>
          <div className={classes.title}>{template.name}</div>
          <div className={classes.text}>{(template as iWheelDefaultTemplate).description || ''}</div>
        </div>

        {(template as iWheelDefaultTemplate).image ? (
          <img
            className={classes.image}
            height="118"
            width="118"
            alt={template.name}
            src={(template as iWheelDefaultTemplate).image}
          />
        ) : accountLogo ? (
          <div className={classes.customContainer}>
            <img className={classes.customBackground} src={AccountLogoBasePic} alt="" />
            <img className={classes.customImage} height="118" width="118" alt={template.name} src={accountLogo} />
          </div>
        ) : (
          <div className={classes.image} />
        )}
      </div>

      {isTemplateAdmin && (
        <div className={classes.contextMenu}>
          <TemplateContextMenu template={template as iWheelTemplate} setTemplateToDelete={setTemplateToDelete} />
        </div>
      )}

      <Accordion expanded={expanded} onChange={() => onExpand(expandId)}>
        <AccordionSummary expandIcon={<ChevronDown size={16} />}>Template Details</AccordionSummary>

        <AccordionDetails>
          <Typography className={classes.primaryText} style={{ textTransform: 'capitalize' }}>
            {template.access}
          </Typography>
          <Typography className={classes.secondaryText}>
            {template.access === WHEEL_ACCESS_TYPES.PRIVATE
              ? 'Private allows you to invite peers to join, you will be able to see the results of everyone who joins.'
              : 'Public is for workshops and conferences. This is an anonymous pulse check.'}
          </Typography>

          <Typography className={classes.primaryText}>Segments</Typography>
          <Typography className={classes.secondaryText}>
            There are {template.segments.length} segments that you will evaluate from 1 - {template.maxScale}
          </Typography>

          <div className={classes.segmentTags}>
            {template.segments.map((s, i) => (
              <SegmentTag key={s.id} title={s.name} isActive={false} index={i} />
            ))}
          </div>

          <div className={classes.controls}>
            <MediumButton onClick={() => setTemplateToUse(template)}>USE TEMPLATE</MediumButton>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '174px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 2px 30px 0 rgba(0, 0, 0, 0.07)',
  },
  summary: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 24px',
  },
  description: {
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column',
  },
  title: {
    marginTop: '16px',
    fontSize: '18px',
    lineHeight: 1.33,
    fontWeight: 500,
    color: '#2b2e31',
  },
  text: {
    margin: '8px 0 24px',
    fontSize: '14px',
    lineHeight: 1.43,
    color: '#7f8b96',
  },
  customContainer: {
    position: 'relative',
    width: '138px',
    height: '138px',
  },
  customBackground: {
    height: '100%',
  },
  customImage: {
    position: 'absolute',
    top: '55%',
    left: '52%',
    transform: 'translate(-50%, -50%)',
    height: '38px',
    width: 'auto',
  },
  image: {
    marginRight: '4px',
    height: '118px',
    width: '118px',
  },
  contextMenu: {
    position: 'absolute' as any,
    top: '16px',
    right: '0',
  },
  primaryText: {
    marginTop: '24px',
    fontSize: '16px',
    lineHeight: 1.5,
    color: '#2b2e31',
  },
  secondaryText: {
    marginTop: '4px',
    fontSize: '12px',
    lineHeight: 1.67,
    color: '#5d666e',
  },
  segmentTags: {
    display: 'flex',
    flexWrap: 'wrap' as any,
    marginTop: '4px',
    '& > div': {
      marginTop: '12px',
    },
  },
  controls: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '32px 0',
  },
});

const Accordion = withStyles({
  root: {
    borderRadius: '4px',
    backgroundColor: '#f8f8f8',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      marginTop: '0',
      backgroundColor: '#fffaf3',
    },
  },
  expanded: {
    marginTop: '0',
    backgroundColor: '#fffaf3',
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    minHeight: '40px',
    backgroundColor: 'transparent',
    '&$expanded': {
      minHeight: '40px',
    },
  },
  content: {
    margin: '8px 0',
    fontSize: '12px',
    color: '#2b2e31',
    '&$expanded': {
      margin: '8px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    position: 'absolute',
    flexDirection: 'column',
    padding: '0 24px',
    backgroundColor: '#fffaf3',
    zIndex: 10,
    boxShadow: '0 20px 20px 0 rgba(0, 0, 0, 0.07)',
  },
})(MuiAccordionDetails);
