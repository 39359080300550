import React, { Component } from 'react';
import * as _ from 'lodash';
import services from '../../../API/services';
import { toasterService } from '../../Shared/Toaster/Toaster.service';
import utils from '../../Shared/utils';
import Alert from '../Alert/Alert';
import { TextInput } from '../../Shared/Inputs/TextInput';

interface iPasswordFormProps {}

interface iPasswordFormState {
  schema: any;
  data: { pass: string; newPass: string; confirmPass: string };
  errors: { pass?: string; newPass?: string; confirmPass?: string };
  touched: { pass: boolean; newPass: boolean; confirmPass: boolean };
  alertMsg: string | null;
}

class PasswordForm extends Component<iPasswordFormProps, iPasswordFormState> {
  readonly state = {
    schema: utils.getFormValidationSchema('password'),
    data: {
      pass: '',
      newPass: '',
      confirmPass: '',
    },
    errors: {},
    touched: {
      pass: false,
      newPass: false,
      confirmPass: false,
    },
    alertMsg: null,
  };

  handleBlur = (event, fieldName) => {
    this.setState({
      touched: { ...this.state.touched, [fieldName]: true },
    });
  };

  handleSave = async (e) => {
    await utils.submittingGuard(e, async () => {
      const { data } = this.state;

      const response = await services.changePassword(data);

      if (response && response.status === 200) {
        this.setState({ alertMsg: 'Your password has been updated.' });
      } else {
        toasterService.addErrorToast(response.data.message);
      }
    });
  };

  handleValueChange = (event, fieldName) => {
    const { schema, data, touched } = this.state;
    const newErrors = utils.validateFields(schema, { ...data, [fieldName]: event.target.value });

    this.setState({
      data: { ...data, [fieldName]: event.target.value },
      touched: { ...touched, [fieldName]: true },
      errors: newErrors ? newErrors : {},
    });
  };

  hideMessage = () => {
    this.setState({ alertMsg: null });
  };

  generateInput = ([id, fieldName, label, placeholder]: string[]) => {
    const { data, touched, errors } = this.state;
    return (
      <div className="form-group mb-px-1" key={id}>
        <div className="row align-items-center">
          <label className="col-12 col-lg-4" htmlFor={'input_' + id}>
            {label}
          </label>
          <div className="col-12 col-lg-8">
            <TextInput
              id={'input_' + id}
              name={fieldName}
              placeholder={placeholder}
              type="password"
              value={data[fieldName]}
              onChange={(e) => this.handleValueChange(e, fieldName)}
              onBlur={(e) => this.handleBlur(e, fieldName)}
              error={errors[fieldName]}
              touched={touched[fieldName]}
            />
          </div>
        </div>
      </div>
    );
  };

  generateInputs = () =>
    [
      ['pass', 'pass', 'Current password', 'Enter current password'],
      ['newPass', 'newPass', 'New password', 'New password'],
      ['confirmPass', 'confirmPass', 'Confirm new password', 'Confirm new password'],
    ].map((options) => this.generateInput(options));

  render() {
    const { alertMsg, errors } = this.state;

    return (
      <div className="settings-form">
        <div className="row">
          <div className="col-12">
            {alertMsg && <Alert alertType="success" text={alertMsg} handleClose={this.hideMessage} />}
          </div>
        </div>
        <form onSubmit={this.handleSave}>
          {this.generateInputs()}
          <div className="btn-wrap mb-5">
            <button
              id="password-change-save"
              type="submit"
              className={`btn btn-outline-primary${!_.isEmpty(errors) ? ' disabled' : ''}`}
              disabled={!_.isEmpty(errors)}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default PasswordForm;
