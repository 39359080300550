import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { makeStyles, Menu, MenuItem, TextField } from '@material-ui/core';
import { Option, StyledEditIcon, StyledTrashIcon } from '../../Dashboard/Card/_styled';
import { DateTime } from 'luxon';
import { COMMENT_DATETIME_FORMAT } from '../../../constants';
import { FiCornerDownRight, FiMoreVertical, FiX } from 'react-icons/fi';
import styled from 'styled-components';
import { Button } from '../../Shared/Buttons/Button';
import { InitialsCircle } from '../../Shared/InitialsCircle/InitialsCircle';
import { useSelector } from 'react-redux';
import { selectUser } from '../../Auth/Auth.selectors';
import { Text } from '../../Shared/Typography/Typography';
import { iWheelMember, WheelMemberRole } from '../../../API/interfaces';
import CommentAttachment from '../../Shared/CommentAttachment/CommentAttachment';
import AddCommentAttachment from './AddCommentAttachment';
import CheckInAssistWhiteIcon from '../../../Assets/images/checkin-assist-white.png';

enum CommentState {
  READ = 'READ',
  EDIT = 'EDIT',
  REPLY = 'REPLY',
  DELETE = 'DELETE',
}

interface CommentPros {
  member: iWheelMember;
  reply: any;
  onCreate: (value) => void;
  onDelete: (replyId) => void;
  onEdit: (replyId, vlaue) => void;
  last: boolean;
  isMain?: boolean;
  isReplyEnabled: boolean;
}

const CommentWrapper = styled.div<any>`
  width: 100%;
  padding: 6px 24px 6px 80px;
  position: relative;
  &:hover {
    background-color: #fcfcfc;
  }
  @media only screen and (max-device-width: 767px) {
    padding: 6px 24px 6px 58px;
  }
`;

const InitialsWrapper = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ReplyWrapper = styled.div`
  width: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  @media only screen and (max-device-width: 767px) {
    width: 100%;
  }
`;

export const Reply = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 4px 5px 4px 5px;
  margin: 5px 0;
  font-size: 12px;
  line-height: 14px;
  color: #2b2e31;
  border: solid 1px #86919a;
  border-radius: 16px;
  background-color: #f8f8f8;
  cursor: pointer;
`;

const ReplyCross = styled.div`
  position: absolute;
  top: -15px;
  right: -20px;
  color: #7b858e;
`;

const CommentControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const NamesBadgeContainer = styled.div`
  display: flex;
  margin: 5px 0;
`;

const AssistBadge = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 20px;
  background-color: #2a3e50;
  margin-right: 8px;
`;

const StyledImage = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 4px;
`;

export const Comment = ({
  member,
  reply,
  onCreate,
  onDelete,
  onEdit,
  last,
  isMain = false,
  isReplyEnabled,
}: CommentPros) => {
  const optionsRef = useRef(null);
  const classes = useStyles();
  const [optionsOpened, setOptionsOpened] = useState(false);
  const [state, setState] = useState(CommentState.READ);
  const [isReply, setReply] = useState(false);
  const [editValue, setEditValue] = useState(reply.comment);
  const [file, setFile] = useState(null);
  const [replyValue, setReplyValue] = useState('');

  const user = useSelector(selectUser);

  const getAttachmentData = (attachment) => {
    if (!attachment) return null;
    const fileName = reply.attachment.match(/\/([^/]+)_\d{4}-\d{2}-\d{2}T\d{2}%3A\d{2}%3A\d{2}.\d{3}Z$/)[1];
    const fileFormat = fileName.split('.').pop() || '';
    return {
      url: reply.attachment,
      name: fileName,
      type: `type/${fileFormat}`,
      size: null,
    };
  };

  useEffect(() => {
    !isReply && reply?.attachment ? setFile(getAttachmentData(reply?.attachment)) : setFile(null);
  }, [reply, isReply]);

  const submitCreateReply = async () => {
    const formData = new FormData();
    replyValue && formData.append('comment', replyValue);
    file && formData.append('attachment', file, file.name);
    await onCreate(formData);
    setReplyValue('');
    setReply(false);
    setFile(null);
  };

  const submitEdit = async () => {
    const formData = new FormData();
    editValue && formData.append('comment', editValue);
    file && formData.append('attachment', file, file.name);
    await onEdit(reply.id, formData);
    setOptionsOpened(false);
    setState(CommentState.READ);
    setFile(null);
  };

  const submitDelete = async () => {
    await onDelete(reply.id);
    setOptionsOpened(false);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const roleBadge = () => {
    if (member?.memberRole === WheelMemberRole.ADMIN) return 'admin';
    if (member?.memberRole === WheelMemberRole.ACCOUNTABILITY_BUDDY) return 'buddy';
    return;
  };

  switch (state) {
    case CommentState.READ:
      return (
        <CommentWrapper>
          <div className="d-flex align-items-center justify-content-between justify-content-lg-start">
            <NamesBadgeContainer>
              <div className={classes.member}>
                <div className="d-flex align-items-center">
                  {member && <div className={classes.initials}>{`${member.firstName[0]}${member.lastName[0]}`}</div>}
                  <span className={classes.memberName}>
                    {member ? `${member.firstName} ${member.lastName}` : 'Anonymous'}
                  </span>
                </div>
              </div>
              {reply.assistant && (
                <AssistBadge>
                  <StyledImage src={CheckInAssistWhiteIcon} />
                  <Text size="12px" color="#fff" weight="200">
                    Assisted by {reply.assistant?.assistantFullName}
                  </Text>
                </AssistBadge>
              )}
            </NamesBadgeContainer>
            <div className="d-flex justify-content-center align-items-center">
              {roleBadge() && (
                <Text size={'12px'} color={'#005ebd'} className="mr-2 d-lg-inline">
                  {roleBadge()}
                </Text>
              )}
              <Text size={'12px'} color={'#2b2e31'} opacity={'0.6'}>
                {DateTime.fromISO(reply.date).toFormat(COMMENT_DATETIME_FORMAT)}
              </Text>
            </div>
          </div>
          <>
            <div className="d-flex justify-content-between">
              <div>
                <Text whiteSpace="break-spaces" size={'14px'} className="mr-2">
                  {reply.comment || '-'}
                </Text>
                {reply.isEdited && (
                  <Text color={'#6a777f'} size={'14px'} opacity={0.75}>
                    (Edited)
                  </Text>
                )}
              </div>
              <div ref={optionsRef} className="d-inline-flex">
                {!isMain && user._id === member?.userId && (
                  <FiMoreVertical
                    size={20}
                    onClick={() => setOptionsOpened(true)}
                    style={{
                      color: optionsOpened ? '#fb463b' : '#0c2337',
                    }}
                    className="cursor-pointer"
                  />
                )}
              </div>
            </div>
            <CommentAttachment reply={reply} />

            {isReply ? (
              <div className="w-100 mt-3">
                <div className="d-flex flex-wrap position-relative">
                  <InitialsWrapper className="d-none d-lg-block">
                    <InitialsCircle
                      boxShadow={'1px 1px 10px 2px rgba(0, 0, 0, 0.3)'}
                      fontSize={'16px'}
                    >{`${user?.first_name[0]}${user?.last_name[0]}`}</InitialsCircle>
                  </InitialsWrapper>
                  <ReplyWrapper>
                    <TextField
                      autoFocus={true}
                      id="shareInput"
                      label="Your Note"
                      fullWidth={true}
                      multiline
                      maxRows={4}
                      variant="outlined"
                      placeholder={'Enter your note'}
                      value={replyValue}
                      onChange={(e) => setReplyValue(e.target.value)}
                      onFocus={(e) =>
                        e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)
                      }
                      className={classes.input}
                      InputLabelProps={{ className: classes.label }}
                    />
                    <CommentControlsWrapper>
                      <AddCommentAttachment file={file} onEdit={handleFileChange} onRemove={() => setFile(null)} />
                      <Button height={'40px'} disabled={!(replyValue || file)} onClick={submitCreateReply}>
                        SHARE
                      </Button>
                    </CommentControlsWrapper>
                  </ReplyWrapper>
                  <ReplyCross>
                    <FiX className="close-button cursor-pointer" size={18} onClick={() => setReply(false)} />
                  </ReplyCross>
                </div>
              </div>
            ) : (
              last &&
              isReplyEnabled && (
                <Reply onClick={() => setReply(true)}>
                  <FiCornerDownRight size={12} style={{ marginRight: '2px' }} /> Reply
                </Reply>
              )
            )}
          </>
          <Menu
            anchorEl={optionsRef.current}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={optionsOpened}
            onClick={(e) => e.stopPropagation()}
            onClose={() => setOptionsOpened(false)}
            MenuListProps={{ disablePadding: true, style: { minWidth: '200px' } }}
            disableAutoFocusItem
          >
            <MenuItem onClick={() => setState(CommentState.EDIT)} className="py-2">
              <StyledEditIcon />
              <Option>Edit</Option>
            </MenuItem>
            <MenuItem onClick={submitDelete} className="py-2">
              <StyledTrashIcon className="mr-lg-2" />
              <Option color="#fb463b">Delete</Option>
            </MenuItem>
          </Menu>
        </CommentWrapper>
      );
    case CommentState.EDIT:
      return (
        <CommentWrapper className="mt-3 pt-3" line={false}>
          <div className="d-flex flex-wrap position-relative">
            <InitialsWrapper className="d-none d-lg-block">
              <InitialsCircle fontSize={'16px'}>{`${member?.firstName[0]}${member?.lastName[0]}`}</InitialsCircle>
            </InitialsWrapper>
            <ReplyWrapper>
              <TextField
                id="editInput"
                autoFocus={true}
                label="Your Note"
                fullWidth={true}
                multiline
                maxRows={4}
                variant="outlined"
                value={editValue}
                onChange={(e) => setEditValue(e.target.value)}
                onFocus={(e) =>
                  e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)
                }
                className={classes.input}
                InputLabelProps={{ className: classes.label }}
              />
              <CommentControlsWrapper>
                <AddCommentAttachment file={file} onEdit={handleFileChange} onRemove={() => setFile(null)} />
                <Button height={'40px'} disabled={!(editValue || file)} onClick={submitEdit}>
                  SHARE
                </Button>
              </CommentControlsWrapper>
            </ReplyWrapper>
            <ReplyCross>
              <FiX
                className="close-button cursor-pointer"
                size={18}
                onClick={() => {
                  setOptionsOpened(false);
                  setState(CommentState.READ);
                }}
              />
            </ReplyCross>
          </div>
        </CommentWrapper>
      );
  }

  return <></>;
};

const useStyles = makeStyles<any>({
  commentCounter: {
    marginLeft: '5px',
    color: '#6a777f',
  },
  commentsGroup: {
    paddingLeft: '15px',
  },
  commentsGroupTitle: {
    margin: '20px 0',
    fontSize: '12px',
    lineHeight: 1.17,
    color: '#2b2e31',
    opacity: 0.6,
  },
  comment: {
    display: 'flex',
  },
  member: {
    display: 'inline-block',
    padding: '4px 5px 4px 5px',
    fontSize: '12px',
    lineHeight: '14px',
    color: '#2b2e31',
    border: 'solid 1px #86919a',
    borderRadius: '16px',
    backgroundColor: '#ffffff',
    marginRight: '8px',
  },
  initials: {
    width: '16px',
    height: '16px',
    padding: '4px 0',
    textAlign: 'center',
    fontSize: '8px',
    lineHeight: '8px',
    borderRadius: '50%',
    color: '#ffffff',
    backgroundColor: '#0f2136',
    boxShadow: '1px 1px 5px 1px rgba(0, 0, 0, 0.2);',
  },
  memberName: {
    margin: '0 5px',
    display: 'inline-block',
    maxWidth: '200px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    ['@media (max-width:767px)']: {
      maxWidth: '100px',
    },
  },
  input: {
    '& .MuiInputBase-root': {
      backgroundColor: '#f8f8f8',
    },
  },
  label: {
    color: '#2b2e31',
    '&.MuiFormLabel-root.Mui-focused': {
      color: '#2b2e31',
    },
  },
});
