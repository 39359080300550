import { DateTime } from 'luxon';
import { HttpClient } from './HttpClient';
import {
  AccountType,
  iCheckinRequest,
  iSegment,
  iWheelDefaultTemplate,
  iWheelExt,
  iWheelTemplate,
  SendSupportMessageParams,
} from './interfaces';
import { TIMELINE_CHECKINS_LIMIT } from '../constants';
import { InsightsDataType } from '../Components/Insights/insights.model';
import { ReportAccessType } from '../Components/WheelPage/common/GenerateReportModal/utils';

const timeZone = DateTime.now().zoneName;

class Services {
  getWheelsByUserId = async () => {
    return HttpClient.get('/wheels').catch((error) => error.response);
  };

  getWheelsExtendedByUserId = async () => {
    return HttpClient.get<iWheelExt[]>('/wheels-extended');
  };

  getDefaultSegmentsList = async (): Promise<Array<iSegment>> => {
    const response = await HttpClient.get('/segments-default');
    return response.data;
  };

  getSegmentsList = async (param) => {
    return HttpClient.get(`/segments?${param.isTemplate ? 'template_id' : 'wheel_id'}=${param.wheelId}`).catch(
      (error) => error.response
    );
  };

  addSegment = async (segment) => {
    return HttpClient.post(`/segments`, segment).catch((error) => error.response);
  };

  updateSegment = async (segment) => {
    return HttpClient.post(`/segments/${segment._id}`, segment).catch((error) => error.response);
  };

  deleteSegment = async (segmentId: string) => {
    return await HttpClient.delete(`/segments/${segmentId}`).catch((error) => error.response);
  };

  createWheel = async (wheel) => {
    return HttpClient.post('/wheels', wheel);
  };

  getWheelById = async (wheelId) => {
    return HttpClient.get(`/wheels/${wheelId}`);
  };

  getWheelByIdUnauthorized = async (wheelId) => {
    return HttpClient.get(`/wheels/${wheelId}/unauthorized`);
  };

  getPublicWheelById = async (wheelId) => {
    return HttpClient.get(`/wheels/${wheelId}/public`);
  };

  updateWheelById = async (param) => {
    return HttpClient.post(`/wheels/${param.id}`, param);
  };

  deleteWheel = async (wheelId: string) => {
    return HttpClient.delete(`/wheels/${wheelId}`).catch((error) => error.response);
  };

  createCheckin = async (wheelId, checkin) => {
    return HttpClient.post(`/wheels/${wheelId}/checkins`, checkin);
  };

  createPublicCheckin = async (wheelId, checkin) => {
    return HttpClient.post(`/wheels/${wheelId}/checkins/public`, checkin);
  };

  deleteCheckin = async (wheelId, checkinId) => {
    return HttpClient.post(`/wheels/${wheelId}/checkins/archive`, { checkinId });
  };

  getWheelCheckinsByUserId = async (params: iCheckinRequest) => {
    const { wheelId, userId, unit, from, to, limit } = params;

    return HttpClient.get(
      this._addParamsToUrl(`/wheels/${wheelId}/members/${userId}/checkins`, {
        ...(unit === 'none' ? { unit, limit: limit || TIMELINE_CHECKINS_LIMIT } : { unit, from, to }),
        timeZone,
      })
    );
  };

  getWheelTeamCheckins = async (params: iCheckinRequest) => {
    const { wheelId, unit, from, to, limit, timeZone: zone } = params;

    return HttpClient.get(
      this._addParamsToUrl(`/wheels/${wheelId}/checkins`, {
        ...(!from || !to ? { unit, limit: limit || TIMELINE_CHECKINS_LIMIT } : { unit, from, to, limit }),
        timeZone: zone ?? timeZone,
      })
    );
  };

  getLatestScores = async (param) => {
    return HttpClient.get(`/wheels/${param.id}/scores/latest/${param.userId}`).catch((error) => error.response);
  };

  getLatestScoresImage = async (param) => {
    const url = this._addParamsToUrl(`/images/wheels/${param.id}/members/${param.userId}`, { timeZone });
    const response = await HttpClient.get(url, {
      headers: { 'Content-Type': 'image/jpeg', Accept: 'image/jpeg' },
      responseType: 'blob',
    });

    if (!response) {
      return null;
    }

    // Blob
    return response.data;
  };

  getLatestTeamScoresImage = async (param) => {
    const url = this._addParamsToUrl(`/images/wheels/${param.id}`, { timeZone: DateTime.now().zoneName });
    const response = await HttpClient.get(url, {
      headers: { 'Content-Type': 'image/jpeg', Accept: 'image/jpeg' },
      responseType: 'blob',
    });

    if (!response) {
      return null;
    }

    // Blob
    return response.data;
  };

  getPdfReport = async ({
    wheelId,
    userId,
    unit,
    from,
    to,
    segmentId,
    checkinDate,
    onlyCommentsWithText,
    reportAccessType,
  }): Promise<Blob> => {
    const paramsObj = {
      unit,
      ...(unit === 'none' ? { limit: TIMELINE_CHECKINS_LIMIT } : { from, to }),
      segmentId,
      checkinDate,
      timeZone: DateTime.now().zoneName,
      onlyCommentsWithText,
      reportAccessType,
    };

    const response = await HttpClient.get(
      this._addParamsToUrl(`/images/wheels/${wheelId}${userId ? `/members/${userId}` : ''}/report`, paramsObj),
      { responseType: 'blob' }
    );

    if (!response) {
      return null;
    }

    // Blob
    return response.data;
  };

  // users
  userLogin = async (credentials: { email: string; password: string }) => {
    return HttpClient.post('/public/login', credentials).catch((error) => error.response);
  };

  userLoginSsoMs = async (ssoData) => {
    return HttpClient.post('/public/login/sso/ms', ssoData).catch((error) => error.response);
  };

  userLoginGetSsoOktaCreds = async (emailOrDomain: string) => {
    return HttpClient.get('/public/login/sso/okta', { params: { email: emailOrDomain } })
      .then((res) => res.data)
      .catch((error) => error.response);
  };

  userLoginSsoOkta = async (ssoData) => {
    return HttpClient.post('/public/login/sso/okta', ssoData).catch((error) => error.response);
  };

  userSignUp = async (userData: { firstName: string; lastName: string; email: string; password: string }) => {
    return await HttpClient.post('/public/signup', userData).catch((error) => error.response);
  };

  userUpdateAccountTag = async (accountTag: AccountType) => {
    return await HttpClient.put('/users/settings/account-tag', { accountTag }).catch((error) => error.response);
  };

  fetchAccountPermissions = async () => {
    return await HttpClient.get('/users/permissions').catch((error) => error.response);
  };

  fetchWheelPermissions = async (wheelId) => {
    return await HttpClient.get(`/users/permissions/wheel/${wheelId}`)
      .then((res) => res.data)
      .catch((error) => error.response);
  };

  resetPasswordStart = async (email: string): Promise<any> => {
    return await HttpClient.post('/public/reset-password-start', {
      email,
    }).catch((error) => error.response);
  };

  resetPasswordComplete = async (data: { email: string; resetCode: string; password: string }): Promise<any> => {
    return await HttpClient.post('/public/reset-password-complete', data).catch((error) => error.response);
  };

  updateUser = async (data: { firstName: string; lastName: string; email: string }) => {
    return await HttpClient.put('/users/settings', data);
  };

  changePassword = async (data: { pass: string; newPass: string }) => {
    return await HttpClient.put('/users/changepassword', data);
  };

  exportData = async (wheelId: string, params: { reportAccessType: ReportAccessType }): Promise<Blob> => {
    const response = await HttpClient.get(this._addParamsToUrl(`/wheels/${wheelId}/export`, params), {
      responseType: 'blob',
    });
    return response.data;
  };

  duplicateWheel = async (wheelId, wheelName) => {
    return await HttpClient.post(`/wheels/${wheelId}/duplicate`, { name: wheelName });
  };

  sendSupportMessage = async (params: SendSupportMessageParams) => {
    return await HttpClient.post('/public/support', params);
  };

  sendDemoRequest = async ({ email, firstName, lastName, requestedFrom }) => {
    return await HttpClient.post('/users/demo-request', { email, firstName, lastName, requestedFrom });
  };

  sendQuestionnaireResult = async ({ email, firstName, lastName, features, message, requestDemo }) => {
    return await HttpClient.post('/users/questionnaire', {
      email,
      firstName,
      lastName,
      features,
      message,
      requestDemo,
    });
  };

  getDefaultTemplates = async (): Promise<Array<iWheelDefaultTemplate>> => {
    const response = await HttpClient.get('/templates-default');
    return response.data;
  };

  getOwnTemplates = async (): Promise<Array<iWheelTemplate>> => {
    const response = await HttpClient.get('/templates');
    return response.data;
  };

  getAccountTemplates = async (): Promise<Array<iWheelTemplate>> => {
    const response = await HttpClient.get('/templates/account-templates');
    return response.data;
  };

  getTemplateById = async (id: string): Promise<iWheelTemplate> => {
    const response = await HttpClient.get(`/templates/${id}`);
    return response.data;
  };

  createTemplate = async (template) => {
    return await HttpClient.post('/templates', template);
  };

  updateTemplate = async (template) => {
    return await HttpClient.put(`/templates/${template.id}`, template);
  };

  deleteTemplate = async (templateId) => {
    return await HttpClient.delete(`/templates/${templateId}`);
  };

  getInsights = async (unit = 'week'): Promise<InsightsDataType> => {
    const url = this._addParamsToUrl('/wheels/insights', { unit });
    const response = await HttpClient.get(url)
      .then((res) => res)
      .catch((error) => error.response);
    return response.data;
  };

  sendRequestToUnlockInsights = async () => {
    return await HttpClient.post('/admin/team-alert-request');
  };

  getIPAddress = async () => {
    return await HttpClient.get('/users/ip-address');
  };

  getWheelAdmins = async () => {
    return await HttpClient.get('/wheels/admins');
  };

  getNotifications = async () => {
    return await HttpClient.get('/notifications');
  };

  markAllNotificationsAsRead = async () => {
    return await HttpClient.put('/notifications');
  };

  markNotificationAsRead = async (params: { id: string }) => {
    return await HttpClient.put(`/notifications/${params.id}`);
  };

  // ignores undefined values
  private _addParamsToUrl = (url, paramsObj: { [key: string]: any } = {}) => {
    const paramsStr = Object.entries(paramsObj)
      .filter(([_, val]) => val !== undefined)
      .map(([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`)
      .join('&');

    return `${url}${paramsStr ? `?${paramsStr}` : ''}`;
  };
}

export default new Services();
