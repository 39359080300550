import React, { FC } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Toast } from './Toast';
import styled from 'styled-components';
import { removeToast } from './Toaster.actions';

interface iToasterProps {
  actions: any;
  toasts: any;
}

const ToasterContainer = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  display: block;
  z-index: 5;
  cursor: pointer;
`;

const Toaster: FC<iToasterProps> = ({ actions, toasts }: iToasterProps) => {
  const { removeToast } = actions;

  return (
    <ToasterContainer>
      {toasts.map((toast) => {
        const { id } = toast;
        return <Toast {...toast} key={id} onClick={() => removeToast(id)} className="ireflect-toaster_toast" />;
      })}
    </ToasterContainer>
  );
};

const mapStateToProps = (state) => ({
  toasts: state.toasts,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ removeToast }, dispatch),
});

export const ToasterComponent = connect(mapStateToProps, mapDispatchToProps)(Toaster);
