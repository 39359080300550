import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { InsightsReducerStateType, InsightsPeriodType } from './insights.model';
import LockIcon from '../../Assets/images/icon-lock.png';
import { CALENDAR_DATE_FORMAT } from '../../constants';
import services from '../../API/services';
import { ManageContainer } from '../_Containers/ManageContainer';
import PeriodButton from './PeriodButton';
import { Text } from '../Shared/Typography/Typography';
import SummaryBadge from './SummaryBadge';
import InsightsComments from './InsightsComments';
import { loadInsightsSuccess, startLoad } from './insights.actions';
import { selectInsights } from './insights.selectors';
import NoInsightsComments from './InsightsComments/NoInsightsComments';
import { Spinner, SpinnerBackground } from '../Shared/Spinner/Spinner';

const ContentContainer = styled.div`
  width: 100%;
  min-height: 700px;
  border-radius: 8px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  position: relative;
  padding: 32px 40px 40px;
  margin: 48px auto;

  @media (max-width: 768px) {
    padding: 0;
    margin: 0;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  justify-content: space-between;

  @media (max-width: 768px) {
    align-items: center;
    padding: 26px 16px 16px 16px;
  }
`;

const HeaderLeftSide = styled.div`
  display: flex;
  align-items: baseline;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SummaryContainer = styled.div`
  display: flex;

  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;

const LockImage = styled.img`
  width: 12px;
  height: 13px;
  margin-left: 4px;
`;

const Insights: React.FC = () => {
  const dispatch = useDispatch();
  const [period, setPeriod] = useState<InsightsPeriodType>('week');
  const insights: InsightsReducerStateType = useSelector(selectInsights);

  const startDate = insights?.periodStart && DateTime.fromISO(insights?.periodStart).toFormat(CALENDAR_DATE_FORMAT);
  const endDate = insights?.periodEnd && DateTime.fromISO(insights?.periodEnd).toFormat(CALENDAR_DATE_FORMAT);
  const dateRange = startDate && endDate && `${startDate} - ${endDate}`;

  useEffect(() => {
    dispatch(startLoad());
    services
      .getInsights(period)
      .then((res) => dispatch(loadInsightsSuccess(res)))
      .catch((error) => error);
  }, [period]);

  const onPeriodChange = (period: InsightsPeriodType) => {
    setPeriod(period);
  };

  const isLockedInsights = !insights?.teamAlertThreshold;

  return (
    <ManageContainer className="gray">
      <div className="container p-0">
        <ContentContainer>
          <HeaderContainer>
            <HeaderLeftSide>
              <Text
                className="mr-2 d-flex justify-content-start"
                size="18px"
                weight="500"
                {...(isLockedInsights ? { color: '#6a777f' } : {})}
              >
                Insights
                {isLockedInsights && <LockImage src={LockIcon} />}
              </Text>
              {!isLockedInsights && <Text size="12px">{dateRange}</Text>}
            </HeaderLeftSide>
            {!isLockedInsights && (
              <PeriodButton firstLabel="Weekly" secondLabel="Monthly" onChange={onPeriodChange} period={period} />
            )}
          </HeaderContainer>
          {insights.isLoading ? (
            <SpinnerBackground height="50vh">
              <Spinner />
            </SpinnerBackground>
          ) : (
            <>
              <SummaryContainer>
                {!isLockedInsights && (
                  <>
                    <SummaryBadge
                      text="members"
                      count={insights?.membersAmount}
                      changePercentage={insights?.membersChange}
                    />
                    <SummaryBadge
                      text="low scores"
                      count={insights?.lowScoresAmount}
                      changePercentage={insights?.lowScoresChange}
                    />
                  </>
                )}
              </SummaryContainer>
              {!isLockedInsights || insights?.comments?.length ? (
                <InsightsComments
                  members={insights?.users}
                  comments={insights?.comments}
                  wheels={insights?.wheels}
                  segments={insights?.segments}
                />
              ) : (
                <NoInsightsComments isLockedInsights={isLockedInsights} period={period} />
              )}
            </>
          )}
        </ContentContainer>
      </div>
    </ManageContainer>
  );
};

export default Insights;
