import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface iConfirmModalProps {
  onConfirmed: () => void;
  message: string;
  title: string;
  button: any;
}

export const ButtonWithConfirmation = (props) => {
  const { onConfirmed, message, title, button }: iConfirmModalProps = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const confirm = async () => {
    onConfirmed();
    toggle();
  };

  return (
    <>
      <div onClick={toggle}>{button}</div>
      <Modal isOpen={modal} toggle={toggle} zIndex={1301}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>{message}</ModalBody>
        <ModalFooter>
          <Button className="ireflect-button btn-red" size="sm" onClick={() => confirm()}>
            Confirm
          </Button>
          <Button className="ireflect-button btn-primary" size="sm" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
