import mixpanel from 'mixpanel-browser';
import { MIXPANEL_ID } from '../constants';
import { eSortOptions } from '../Components/Dashboard/_types';

const env_check = process.env.NODE_ENV === 'production';

// let env_check = true;

class Mixpanel {
  constructor() {
    mixpanel.init(MIXPANEL_ID);
  }

  identify(id) {
    if (env_check) {
      mixpanel.identify(id);
    }
  }

  alias(id) {
    if (env_check) {
      mixpanel.alias(id);
    }
  }

  track(name, props = undefined) {
    if (env_check) {
      mixpanel.track(name, props);
    }
  }

  people = {
    set: (props) => {
      if (env_check) {
        mixpanel.people.set(props);
      }
    },
  };

  onboarding_tag_personal() {
    this.track('onb_personal_web');
  }

  onboarding_tag_business() {
    this.track('onb_business_web');
  }

  onboarding_tag_school() {
    this.track('school_web');
  }

  signUp() {
    this.track('signup_web');
  }

  signUpSSO() {
    this.track('signup_web_sso');
  }

  login() {
    this.track('login_web');
  }

  loginSSO() {
    this.track('login_web_sso');
  }

  forgotPasswordLink() {
    this.track('click_forgot_password_web');
  }

  forgetPassword() {
    this.track('forgot_pass_save_new_pas_web');
  }

  createWheelAttempt() {
    this.track('create_wheel_web');
  }

  createWheelSubmit(segmentsCount, access) {
    this.track(`create_${access}_wheel_submit_web`, { segmentsCount });
  }

  wheelSettings() {
    this.track('settings_web');
  }

  deleteWheel() {
    this.track('delete_wheel_web');
  }

  duplicate_wheel() {
    this.track('duplicate_web');
  }

  createCheckinAttempt(isPublic) {
    this.track(isPublic ? 'public_checkin_web' : 'checkin_web');
  }

  createCheckinSubmit(isPublic) {
    this.track(isPublic ? 'public_checkin_submit_web' : 'checkin_submit_web');
  }

  publicWheelSignUp() {
    this.track('signup_public_wheel');
  }

  publicWheelLogin() {
    this.track('login_public_wheel');
  }

  saveImage(share = false) {
    this.track(share ? 'save_share_web' : 'save_image_web');
  }

  joinWheel() {
    this.track('join_wheel');
  }

  joinBuddyRoleWheel() {
    this.track('buddy_joined');
  }

  shareWheel() {
    this.track('invite_member_web');
  }

  duplicateOnWheelPage() {
    this.track('duplicate_wp_web');
  }

  settingsOnWheelPage() {
    this.track('wheel_settings_wp_web');
  }

  checkinReminderTab() {
    this.track('checkin_reminder_tab');
  }

  checkinReminderMemberOff() {
    this.track('checkin_reminder_member_off');
  }

  checkinReminderAdminOff() {
    this.track('checkin_reminder_admin_off');
  }

  checkinReminderAdminOn() {
    this.track('checkin_reminder_admin_on');
  }

  leaveWheel() {
    this.track('leave_wheel_web');
  }

  clickInviteLink() {
    this.track('click_invite_link');
  }

  clickInviteBuddy() {
    this.track('click_invite_buddy');
  }

  clickGenerateReport() {
    this.track('click_generate_report');
  }

  generatePdfReport(tab, isAdmin) {
    const eventName = {
      myResults: 'generate_pdf_my_results',
      teamResults: 'generate_pdf_team_results',
      memberResults: 'generate_pdf_member_results',
    }[tab];
    const props = tab === 'myResults' ? { role: isAdmin ? 'admin' : 'member' } : {};

    this.track(eventName, props);
  }

  clickHelp(location) {
    const payload = { location: { beforeSignIn: 'before', afterSignIn: 'after', publicCheckin: 'public' }[location] };

    this.track('click_help', payload);
  }

  dashboardSearch() {
    this.track('dashboard_search');
  }

  dashboardSorting(type: eSortOptions) {
    this.track('dashboard_sorting', { type });
  }

  moveToFolder() {
    this.track('move_to_folder');
  }

  clickInsightsFree() {
    this.track('insights_free');
  }

  clickInsightsPaid() {
    this.track('insights_paid');
  }

  unlockRedFlags() {
    this.track('unlock_red_flags');
  }

  supportNowClick() {
    this.track('support_now_click');
  }

  supportNowSent() {
    this.track('support_now_sent');
  }

  markAllNotificationsAsRead() {
    this.track('notifications_readall_web');
  }

  openNotifications() {
    this.track('notifications_web');
  }
}

export const mixpanelAnalytics = new Mixpanel();
