import React, { Component, useState } from 'react';
import { AuthContainer } from './AuthContainer';
import { TextInput } from '../Shared/Inputs/TextInput';
import './ResetPasswordComplete.scss';
import { Button } from '../Shared/Buttons/Button';
import services from '../../API/services';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Title } from '../Shared/Typography/Typography';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Toast } from '../Shared/Toaster/Toast';
import { analytics } from '../../analytics/analytics';
import { ToastTypes } from '../Shared/Toaster/Toaster.actions';
import { toasterService } from '../Shared/Toaster/Toaster.service';
import { authService } from './Auth.service';

type iSetNewPasswordProps = RouteComponentProps;

const initialValues = {
  password: '',
};

const validationSchema = yup.object({
  password: yup.string().min(6, 'Password must contain at least 6 characters').required('The field can’t be empty'),
});

const ResetPasswordCompleteComponent = (props: iSetNewPasswordProps) => {
  const [serverError, setServerError] = useState('');
  const parseQueryParams = (): {
    email: string | null;
    resetCode: string | null;
  } => {
    const query = new URLSearchParams(props.location.search);
    const resetCode = query.get('resetCode');
    const email = query.get('email');
    return {
      email,
      resetCode,
    };
  };

  const { email, resetCode } = parseQueryParams();

  const login = async (email, password) => {
    const loginResponse = await services.userLogin({ email, password });
    if (loginResponse.status === 200) {
      authService.login({
        token: loginResponse.data.access_token,
        ...loginResponse.data.user,
      });
      toasterService.addSuccessToast('Your password is successfully changed!');
      props.history.push('/dashboard');
    } else {
      setServerError(loginResponse.data.message);
    }
  };

  const handleSubmit = async ({ password }) => {
    if (email && resetCode) {
      const response = await services.resetPasswordComplete({
        email,
        password,
        resetCode,
      });
      if (response.status === 200) {
        login(email, password);
        analytics.saveNewPassword();
      } else {
        setServerError(response.data.message);
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <AuthContainer>
      <div className="set-password">
        {serverError && (
          <div className="mb-4">
            <Toast type={ToastTypes.ERROR} message={serverError} />
          </div>
        )}
        <div className="mb-4">
          <Title>Create Password</Title>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <TextInput
              id="set-password_input"
              label="Password"
              placeholder="Enter Your New Password"
              type="password"
              name="password"
              onChange={formik.handleChange('password')}
              onBlur={formik.handleBlur('password')}
              value={formik.values.password}
              error={formik.errors.password}
              touched={formik.touched.password}
            />
          </div>
          <div>
            <Button type="submit" disabled={!(formik.isValid && formik.dirty)}>
              Save Password
            </Button>
          </div>
        </form>
      </div>
    </AuthContainer>
  );
};

export const ResetPasswordComplete = withRouter(ResetPasswordCompleteComponent);
