import React, { FC } from 'react';
import { Lock, User, UserCheck, Users } from 'react-feather';
import { iUser, iWheel, WheelAccess, WheelMemberRole, WheelRoleNaming } from '../../../../API/interfaces';
import './WheelLabel.scss';
import { getWheelRoleWithAnonymous } from '../../../Shared/utils';
import { WHEEL_ACCESS_TYPES } from '../../../../constants';
import { FiLifeBuoy } from 'react-icons/fi';

interface iWheelLabelProps {
  label: string;
  Icon: FC<any>;
}

const WheelLabel = ({ label, Icon }: iWheelLabelProps) => (
  <div className="wheel-label">
    <div className="d-flex align-items-center">
      <Icon color="#ffffff" size={12} className="wheel-label-icon" />
      <span>{label}</span>
    </div>
  </div>
);

interface iAccessLabelProps {
  access: WheelAccess;
}

export const AccessLabel = ({ access }: iAccessLabelProps) => {
  const label = { public: 'Public', private: 'Private' }[access];
  const icon = { public: Users, private: Lock }[access];

  return <WheelLabel label={label} Icon={icon} />;
};

interface iMembershipLabelProps {
  wheel: iWheel;
  user: iUser;
}

export const MembershipLabel = ({ wheel, user }: iMembershipLabelProps) => {
  const wheelRole = getWheelRoleWithAnonymous(wheel, user?._id);
  const membership =
    user && wheelRole !== WheelMemberRole.MEMBER
      ? WheelRoleNaming[wheelRole]
      : wheel.access === WHEEL_ACCESS_TYPES.PRIVATE
      ? WheelRoleNaming.member
      : WheelRoleNaming.anonymous;
  const icon = {
    admin: UserCheck,
    member: User,
    accountability_buddy: FiLifeBuoy,
    anonymous: User,
  };

  return <WheelLabel label={`I am ${membership}`} Icon={icon[wheelRole]} />;
};
