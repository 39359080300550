import _ from 'lodash';

import { eSortOptionValues, iHasChild, iHasId, iHasParent, iWheelFilter, tPartialWheelSort } from './_types';
import { Folder, iCheckinsAggregation, iWheelExt, iWheelExtMapped } from '../../API/interfaces';
import { getAverageScore } from '../Shared/utils';

export const capitalize = (value: string) =>
  value.toLowerCase().replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));

export const formatString = (tag: string) => capitalize(tag.replaceAll('_', ' '));

export const formatTag = (tag: string) =>
  tag
    .replaceAll('_', ' ')
    .toLowerCase()
    .replace(/^\w/, (c) => c.toUpperCase());

export const wheelComparer = (sort: tPartialWheelSort, wheel1: iWheelExtMapped, wheel2: iWheelExtMapped) => {
  if (sort.BY_LATEST_UPDATES) {
    if (sort.BY_LATEST_UPDATES === eSortOptionValues.DESC) {
      return wheel1.latestResultUpdateAt > wheel2.latestResultUpdateAt ? -1 : 1;
    }
  }
  if (sort.BY_CREATION_DATE) {
    if (sort.BY_CREATION_DATE === eSortOptionValues.DESC) {
      return new Date(wheel1.createDate) > new Date(wheel2.createDate) ? -1 : 1;
    }
    if (sort.BY_CREATION_DATE === eSortOptionValues.ASC) {
      return new Date(wheel1.createDate) < new Date(wheel2.createDate) ? -1 : 1;
    }
  }
  if (sort.BY_SCORE) {
    if (sort.BY_SCORE === eSortOptionValues.DESC) {
      // @ts-ignore
      return wheel1.averageScore > wheel2.averageScore ? -1 : 1;
    }
    if (sort.BY_SCORE === eSortOptionValues.ASC) {
      // @ts-ignore
      return wheel1.averageScore < wheel2.averageScore ? -1 : 1;
    }
  }
  if (sort.BY_CHECKINS) {
    if (sort.BY_CHECKINS === eSortOptionValues.DESC) {
      return wheel1.checkinsCount > wheel2.checkinsCount ? -1 : 1;
    }
    if (sort.BY_CHECKINS === eSortOptionValues.ASC) {
      return wheel1.checkinsCount < wheel2.checkinsCount ? -1 : 1;
    }
  }
  if (sort.BY_ALPHABET) {
    if (sort.BY_ALPHABET === eSortOptionValues.DESC) {
      return wheel1.name > wheel2.name ? -1 : 1;
    }
    // @ts-ignore
    if (sort.BY_ALPHABET === eSortOptionValues.ASC) {
      return wheel1.name < wheel2.name ? -1 : 1;
    }
  }
};
export const wheelComparerCurried = _.curry(wheelComparer);

export const applySearch = (query: string, wheel: iWheelExtMapped) => {
  const queryLowerCaseArray = query.toLowerCase().trim().split(' ');
  const arrayToTest = wheel.isAdmin
    ? [
        wheel.name,
        ...(wheel.latestScores || []).map((score: any) => score.segment_name),
        ...(wheel.members || []).map((member) => `${member.firstName} ${member.lastName}`),
      ]
    : [wheel.name, ...(wheel.latestScores || []).map((score: any) => score.segment_name)];
  const stringToTest = arrayToTest.join(' ').toLowerCase();

  return queryLowerCaseArray.find((queryWord) => stringToTest.indexOf(queryWord) !== -1);
};

export const applySearchCurried = _.curry(applySearch);

export const applyTags = (filter: iWheelFilter, userId: string, wheel: iWheelExtMapped) => {
  const { isAdmin, isMember, isAccountabilityBuddy } = wheel;
  let ownershipTest = true;
  let wheelTypeTest = true;

  const isPrivate = wheel.access === 'private';
  const isPublic = wheel.access === 'public';

  if (filter.I_AM_MEMBER && filter.I_AM_ADMIN && filter.I_AM_ACCOUNTABILITY_BUDDY) {
    ownershipTest = isAdmin || isMember || isAccountabilityBuddy;
  } else {
    ownershipTest =
      (filter.I_AM_ADMIN && isAdmin) ||
      (filter.I_AM_MEMBER && isMember) ||
      (filter.I_AM_ACCOUNTABILITY_BUDDY && isAccountabilityBuddy) ||
      (!filter.I_AM_MEMBER && !filter.I_AM_ADMIN && !filter.I_AM_ACCOUNTABILITY_BUDDY);
  }

  if (filter.PUBLIC && filter.PRIVATE) {
    wheelTypeTest = isPrivate || isPublic;
  } else {
    wheelTypeTest = (filter.PUBLIC && isPublic) || (filter.PRIVATE && isPrivate) || (!filter.PUBLIC && !filter.PRIVATE);
  }
  return ownershipTest && wheelTypeTest;
};
export const applyTagsCurried = _.curry(applyTags);

export const shallApplySearch = (query: string) => query.length >= 2;

export const wasTagApplied = (filter: iWheelFilter) => Object.values(filter).some(Boolean);

export const wasSortApplied = (sort: tPartialWheelSort) => !_.isEmpty(sort);

export const toNumberOfCheckins = (acc: number, checkin: iCheckinsAggregation) => acc + checkin.numberOfCheckins;

export const getLatestScoresCreateDateOr = (defaultValue: Date | null, latestScores: any[]) => {
  if (latestScores && latestScores.length) {
    return new Date(_.head(latestScores).createDate);
  }
  return defaultValue;
};

export const generateSegmentsData = (wheel: iWheelExt) =>
  wheel.segments
    .filter((seg) => seg.name !== '_comments')
    .map((seg) => {
      const score = Math.ceil(Math.random() * wheel.maxScale);

      return {
        label: seg.name,
        value: score,
        percent: Math.round(score) / wheel.maxScale,
      };
    });

type tBuildTreeByParent = <T extends iHasParent & iHasId>(items: T[]) => Array<T & iHasChild<T>>;
export const buildTreeByParent: tBuildTreeByParent = (items) => {
  const roots = [];
  const itemsDictionaryById = items.reduce(
    (acc, item) => Object.assign(acc, { [item.id]: Object.assign(item, { child: [] }) }),
    {}
  );

  items.forEach((item) => {
    if (item.parent) {
      itemsDictionaryById[item.parent].child.push(item);
    } else {
      roots.push(item);
    }
  });
  return roots;
};

export const folderEq = _.curry(_.matchesProperty)('folder');
export const idEq = _.curry(_.matchesProperty)('id');

export const toExtendedWheel = (userId: string, wheel: iWheelExt): iWheelExtMapped => ({
  ...wheel,
  isAdmin: wheel.members.findIndex((member) => member.userId === userId && member.memberRole === 'admin') !== -1,
  isMember: wheel.members.findIndex((member) => member.userId === userId && member.memberRole === 'member') !== -1,
  isAccountabilityBuddy:
    wheel.members.findIndex((member) => member.userId === userId && member.memberRole === 'accountability_buddy') !==
    -1,
  averageScore: getAverageScore(wheel.latestScores),
  latestResultUpdateAt: getLatestScoresCreateDateOr(new Date(wheel.createDate), wheel.latestScores),
});

const folderWheelsContains = (folder: Folder, wheel: iWheelExt) => folder.wheels.includes(wheel.id);

export const folderWheelsContainsCurried = _.curry(folderWheelsContains);
