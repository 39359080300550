import React, { useState } from 'react';
import './Greeting.scss';
import { Text, Title } from '../../Shared/Typography/Typography';
import personalBusinessImage from '../../../Assets/images/onboarding/image-personal-business.png';
import otherImage from '../../../Assets/images/onboarding/image-other.png';
import { AccountType } from '../../../API/interfaces';
import styled from 'styled-components';
import { Collapse } from '@material-ui/core';

export const Greeting = ({ nextStep }) => {
  const [isOther, setOther] = useState(false);

  return (
    <div className="greeting">
      <Title className="w-100 d-block text-center">Hello</Title>
      <Text className="w-100 d-block text-center mb-5" size="20px" weight={500}>
        What is your main use for ireflect?
      </Text>
      <CardsContainer>
        <GreetingCard onClick={() => nextStep(AccountType.SCHOOL)}>
          <Text className="greeting_card-title" weight={700} size="24px">
            School
          </Text>
          <img src={personalBusinessImage} className="greeting_card-image" />
        </GreetingCard>
        <GreetingCard isSelected={isOther} onClick={() => setOther(!isOther)}>
          <Text className="greeting_card-title" weight={700} size="24px" color={isOther ? '#fff' : '#2b2e31'}>
            Other
          </Text>
          <img src={otherImage} className="greeting_card-image" />
        </GreetingCard>
      </CardsContainer>
      <Collapse in={isOther} timeout="auto" unmountOnExit>
        <OtherTagContainer>
          <OtherTag onClick={() => nextStep(AccountType.PERSONAL)}>
            <Text size="18px" weight={500}>
              Personal
            </Text>
          </OtherTag>
          <OtherTag onClick={() => nextStep(AccountType.WORK)}>
            <Text size="18px" weight={500}>
              Work
            </Text>
          </OtherTag>
          <OtherTag onClick={() => nextStep(AccountType.SPORT)}>
            <Text size="18px" weight={500}>
              Sport
            </Text>
          </OtherTag>
          <OtherTag onClick={() => nextStep(AccountType.WELLBEING)}>
            <Text size="18px" weight={500}>
              Wellbeing
            </Text>
          </OtherTag>
          <OtherTag onClick={() => nextStep(AccountType.MENTAL_HEALTH)}>
            <Text size="18px" weight={500}>
              Mental Health
            </Text>
          </OtherTag>
          <OtherTag onClick={() => nextStep(AccountType.OTHER_PROGRAM)}>
            <Text size="18px" weight={500}>
              Other Program
            </Text>
          </OtherTag>
        </OtherTagContainer>
      </Collapse>
    </div>
  );
};

const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 950px;
  @media screen and (max-width: 991px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
`;

const OtherTagContainer = styled.div`
  width: 950px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 40px;
  @media screen and (max-width: 991px) {
    width: 100%;
    padding: 20px 40px;
    margin-top: 0px;
  }
`;

const OtherTag = styled.div`
  width: 100%;
  height: 72px;
  padding: 24px;
  background-color: #fff;
  margin-bottom: 8px;
  border-radius: 5px;
  box-shadow: 10px -5px 15px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  @media screen and (max-width: 991px) {
    width: 460px;
  }
`;

const GreetingCard = styled.div<{ isSelected?: boolean }>`
  width: 460px;
  height: 160px;
  overflow: hidden;
  position: relative;
  background-color: ${({ isSelected }) => (isSelected ? '#0f2136' : '#fff')};
  box-shadow: 0 -5px 15px 0px rgba(0, 0, 0, 0.05);
  z-index: 0;
  border-radius: 5px;
  cursor: pointer;

  @media screen and (max-width: 991px) {
    height: 110px;
    margin-bottom: 20px;
    svg {
      margin-right: 10px;
    }
  }
`;
