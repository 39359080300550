import React from 'react';
import './Footer.scss';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div id="footer-wrap">
      <div id="footer" className="text-white">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="site-info">
                © {currentYear} ireflect. All rights reserved.
                {/*Developed by <a href="http://www.lumiit.com.au" style={{textDecoration: 'underline'}}>Lumi IT Group</a>. */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
