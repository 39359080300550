import { createTheme } from '@material-ui/core';

const ireflectTheme = createTheme({
  typography: {
    fontFamily: 'Rubik',
  },
  palette: {
    primary: {
      light: '#ff815d',
      main: '#ff5d57',
      dark: '#ff3333',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#1adbdb',
      main: '#14acac',
      dark: '#119689',
    },
    text: {
      primary: '#2a3e50',
    },
    background: {
      default: '#ffffff',
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '35px',
        height: '40px',
        lineHeight: '40px',
        fontSize: '12px',
        transition: 'none',
        padding: '0 24px!important',
        minWidth: 'auto',
      },
      contained: {
        backgroundColor: '#ffffff',
        '&:hover': {
          backgroundColor: '#ffffff',
        },
      },
      containedPrimary: {
        backgroundColor: 'none',
        backgroundImage: 'linear-gradient(117deg,#fa8868 1%,#ff554d)',
        '&.Mui-disabled': { opacity: 0.5, color: '#ffffff' },
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: '#ffffff',
        '&$focused $notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.45)',
          borderWidth: '1px',
        },
        '&:hover $notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.45)',
        },
      },
      notchedOutline: {
        border: '1px solid rgba(0, 0, 0, 0.23)',
      },
      adornedEnd: {
        paddingRight: 5,
      },
    },
    MuiFormLabel: {
      root: {
        color: 'rgba(0, 0, 0, 0.5)',
        '&$focused': {
          color: 'rgba(0, 0, 0, 0.6)',
        },
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTextField: {
      root: {
        backgroundColor: '#ffffff',
        borderRadius: '4px',
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: '#ffffff',
        borderRadius: '4px',
      },
      select: {
        '&:focus': {
          backgroundColor: '#ffffff',
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: 0,
      },
    },
    MuiListItem: {
      root: {
        fontSize: '14px !important',
        fontWeight: 'normal',
        paddingLeft: '24px !important',
        paddingRight: '24px !important',
        minHeight: '40px !important',
      },
    },
  },
});

export default ireflectTheme;
