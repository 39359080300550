import React, { Component } from 'react';
import * as _ from 'lodash';
import services from '../../../API/services';
import { iUser } from '../../../API/interfaces';
import { authService } from '../../Auth/Auth.service';
import { toasterService } from '../../Shared/Toaster/Toaster.service';
import utils from '../../Shared/utils';
import Alert from '../Alert/Alert';
import { TextInput } from '../../Shared/Inputs/TextInput';

interface iSettingsFormProps {
  user: iUser;
}

interface iSettingsFormState {
  schema: any;
  userData: { firstName: string; lastName: string; email: string };
  errors: { firstName?: string; lastName?: string; email?: string };
  touched: { firstName: boolean; lastName: boolean; email: boolean };
  alertMsg: string | null;
}

class SettingsFormComponent extends Component<iSettingsFormProps, iSettingsFormState> {
  constructor(props) {
    super(props);
    const { user } = props;

    this.state = {
      schema: utils.getFormValidationSchema('settings'),
      userData: {
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
      },
      errors: {},
      touched: {
        firstName: false,
        lastName: false,
        email: false,
      },
      alertMsg: null,
    };
  }

  handleBlur = (event, fieldName) => {
    this.setState({ touched: { ...this.state.touched, [fieldName]: true } });
  };

  handleSave = async (e) => {
    await utils.submittingGuard(e, async () => {
      const { userData } = this.state;
      const { user } = this.props;

      const response = await services.updateUser(userData);
      if (!response || response.status !== 200) {
        toasterService.addErrorToast(response.data.message);
        return;
      }

      this.setState({ alertMsg: 'User information has been updated.' });
      const { lastName, firstName, email } = userData;
      authService.login({ ...user, first_name: firstName, last_name: lastName, email });
    });
  };

  handleValueChange = (event, fieldName) => {
    const { userData, touched } = this.state;

    this.setState({
      userData: { ...userData, [fieldName]: event.target.value },
      touched: { ...touched, [fieldName]: true },
    });
  };

  hideMessage = () => {
    this.setState({ alertMsg: null });
  };

  generateInput = ([id, fieldName, label, placeholder]: string[]) => {
    const { userData, touched, errors } = this.state;

    return (
      <div className="form-group mb-px-1" key={id}>
        <div className="row align-items-center">
          <label className="col-12 col-lg-4" htmlFor={'input_' + id}>
            {label}
          </label>
          <div className="col-12 col-lg-8">
            <TextInput
              id={'input_' + id}
              name={fieldName}
              placeholder={placeholder}
              value={userData[fieldName]}
              onChange={(e) => this.handleValueChange(e, fieldName)}
              onBlur={(e) => this.handleBlur(e, fieldName)}
              error={errors?.[fieldName]}
              touched={touched[fieldName]}
            />
          </div>
        </div>
      </div>
    );
  };

  generateInputs = () =>
    [
      ['fname', 'firstName', 'First name', 'First name'],
      ['lname', 'lastName', 'Last name', 'Last name'],
      ['email', 'email', 'Email', 'Email'],
    ].map((options) => this.generateInput(options));

  componentDidUpdate() {
    const { schema, userData, errors } = this.state;

    // check for the input errors
    const newErrors = utils.validateFields(schema, userData);
    !_.isEqual(errors, newErrors) && this.setState({ errors: newErrors });
  }

  render() {
    const { alertMsg, errors } = this.state;

    return (
      <div className="settings-form">
        <div className="row">
          <div className="col-12">
            {alertMsg && <Alert alertType="success" text={alertMsg} handleClose={this.hideMessage} />}
          </div>
        </div>

        <form onSubmit={this.handleSave}>
          {this.generateInputs()}
          <div className="btn-wrap">
            <button
              id="settings-save"
              className={`btn btn-outline-primary${!_.isEmpty(errors) ? ' disabled' : ''}`}
              type="submit"
              disabled={!_.isEmpty(errors)}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default SettingsFormComponent;
