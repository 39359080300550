import React from 'react';
import { AccountRole, iAccount } from '../../API/interfaces';

interface AccountInfoProps {
  account: iAccount;
  accountRole: AccountRole;
}

export const AccountInfo = ({ account, accountRole }: AccountInfoProps) => {
  return (
    <div className="form-group mb-px-1">
      <div className="row align-items-center mb-4">
        <label className="col-12 col-lg-4 m-0">Account name</label>
        <div className="col-12 col-lg-8">{account.accountName}</div>
      </div>
      <div className="row align-items-center">
        <label className="col-12 col-lg-4 m-0">Account role</label>
        <div className="col-12 col-lg-8 text-capitalize">{accountRole}</div>
      </div>
    </div>
  );
};
