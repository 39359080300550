import { AuthActions } from './Auth.actions';

export type UserIpAddressType = {
  ipAddress: string;
  country: string;
};

export type UserPermissionsType = {
  create_wheel_isTemplate: boolean;
  create_wheel_isScoreComments: boolean;
  create_wheel_step: boolean;
  see_assist_info_modal: boolean;
};

const initialState = {
  user: null,
  accountPermissions: null,
  userIpAddress: {
    ipAddress: '',
    country: '',
  },
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case AuthActions.LOGIN:
      return Object.assign({}, state, {
        user: action.payload,
      });
    case AuthActions.TOKEN:
      return Object.assign({}, state, {
        user: { ...state.user, token: action.payload },
      });
    case AuthActions.ACCOUNT_PERMISSIONS:
      return Object.assign({}, state, {
        accountPermissions: action.payload,
      });
    case AuthActions.LOGOUT:
      return Object.assign({}, state, {
        user: null,
        accountPermissions: null,
      });
    case AuthActions.GET_IP_ADDRESS:
      return Object.assign({}, state, {
        userIpAddress: action.payload,
      });
    case AuthActions.HIDE_CHECKIN_ASSIST_INFO_MODAL:
      return {
        ...state,
        accountPermissions: {
          ...state.accountPermissions,
          see_assist_info_modal: false,
        },
      };
    default:
      break;
  }

  return state;
}

export default authReducer;
