import CustomBranding from '../../Assets/images/features/custom-branding.png';
import MultiAdminImage from '../../Assets/images/features/multiple-admin.png';
import TeamAlertsImage from '../../Assets/images/features/team-alerts.png';
import CustomisationImage from '../../Assets/images/features/customisation.png';
import SupportImage from '../../Assets/images/features/support.png';
import PDFReportsImage from '../../Assets/images/features/pdf-reports.png';
import InsightsImage from '../../Assets/images/features/insights.png';

export type FeatureType = 'customBranding' | 'multiAdmin' | 'teamAlerts' | 'customisation' | 'support';

type Feature = {
  type: FeatureType;
  title: string;
  description: string;
  image: string;
};

export default [
  {
    type: 'insights',
    title: 'Insights',
    description:
      'Insight hub is a paid feature that enables you to get insights about all members connected in all wheels you are an administrator of in your community. This is a crucial feature if you have a high volume of users and don’t want to miss any checkins that may need to be actioned on.',
    image: InsightsImage,
  },
  {
    type: 'pdfReport',
    title: 'PDF report',
    description:
      'Generate instant PDF summary documents taking the complexity out of reporting, giving you more time for conversations.',
    image: PDFReportsImage,
  },
  {
    type: 'multiAdmin',
    title: 'Multiple administrators',
    description:
      'Add others as additional admins to your check ins. Admins can edit, view, export reports and edit reminders.',
    image: MultiAdminImage,
  },
  {
    type: 'customBranding',
    title: 'Custom branding',
    description: 'Add your organisation’s brand to ireflect’s platform. Each check in will display your logo.',
    image: CustomBranding,
  },
  {
    type: 'teamAlerts',
    title: 'Alerts for low-scoring team members',
    description:
      'Team alerts are a premium feature to ensure leaders know when team members have submitted low scores.',
    image: TeamAlertsImage,
  },
  {
    type: 'customisation',
    title: 'Additional customisation',
    description:
      'Upgraded accounts gain access to additional options for customisation, including changes to comments and scoring.',
    image: CustomisationImage,
  },
  {
    type: 'support',
    title: 'Additional support',
    description:
      'We’ve got support available to help you implement ireflect in your team and leverage the powerful insights of the platform for your organisation.',
    image: SupportImage,
  },
] as Array<Feature>;
