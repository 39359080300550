enum ENV {
  LOCAL = 'local',
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
}

const apiHosts = {
  [ENV.LOCAL]: 'http://localhost:4800',
  [ENV.DEVELOPMENT]: 'https://api.dev.ireflect.com.au',
  [ENV.PRODUCTION]: 'https://api.ireflect.com.au',
};

const msalIds = {
  [ENV.LOCAL]: 'a663c2d3-943e-4d56-ac76-8310b9ec8f9e',
  [ENV.DEVELOPMENT]: 'a663c2d3-943e-4d56-ac76-8310b9ec8f9e',
  [ENV.PRODUCTION]: '94e64b6b-7d94-47a7-ab97-66fa55d87bee',
};

export const apiBase = apiHosts[process.env.REACT_APP_ENV];
export const msalClientId = msalIds[process.env.REACT_APP_ENV];

export const axiosDefaultOptions = {
  baseURL: apiBase,
  timeout: 100 * 1000,
};
