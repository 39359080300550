import { Greeting } from './Greeting';
import { analytics } from '../../../analytics/analytics';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import services from '../../../API/services';
import { useDispatch, useSelector } from 'react-redux';
import { selectInvitationLink } from '../../Invitations/invitation.selectors';
import { resetInvitationLink } from '../../Invitations/invitation.actions';
import { toasterService } from '../../Shared/Toaster/Toaster.service';
import { authService } from '../Auth.service';
import { AccountType } from '../../../API/interfaces';
import { RootState } from '../../../Redux/Reducers';

type iOnboardingProps = RouteComponentProps;

export const OnboardingComponent = ({ history }: iOnboardingProps) => {
  const invitationLink = useSelector(selectInvitationLink);
  const shareLinkParams = useSelector((state: RootState) => (state.shareLink ? state.shareLink.joinParams : ''));
  const dispatch = useDispatch();

  const chooseTag = async (tag: AccountType) => {
    switch (tag) {
      case AccountType.PERSONAL:
        analytics.onb_signup_personal();
        break;
      case AccountType.WORK:
        analytics.onb_signup_business();
        break;
      case AccountType.SCHOOL:
        analytics.onb_signup_school();
        break;
      default:
        break;
    }

    const res = await services.userUpdateAccountTag(tag);

    if (res.status === 200) {
      await authService.updateUserData();
      if (invitationLink) {
        dispatch(resetInvitationLink());
        history.push(invitationLink);
      } else if (shareLinkParams) {
        history.push(`/share${shareLinkParams}&step=browser`);
      } else {
        history.push('/dashboard');
      }
    } else {
      toasterService.addErrorToast('Something went wrong');
      window.location.href = '/onboarding';
    }
  };

  return (
    <div className="onboarding">
      <Greeting nextStep={(type: AccountType) => chooseTag(type)} />
    </div>
  );
};

export const Onboarding = withRouter(OnboardingComponent);
