import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { iUser, iWheel, iWheelMember, WheelMemberRole, WheelRoleNaming } from '../../../../API/interfaces';
import { MembersClient } from '../../../../API/members.client';
import { Text } from '../../../Shared/Typography/Typography';
import { toasterService } from '../../../Shared/Toaster/Toaster.service';
import utils, { getWheelRole } from '../../../Shared/utils';
import { Search } from './Search';
import { WheelMembersInviteButton } from './WheelMembersInviteButton';
import WheelMembersList from './WheelMembersList';
import { connect } from 'react-redux';
import { WHEEL_ACCESS_TYPES } from '../../../../constants';
import NewFeatureBadge from '../../../Shared/NewFeatureBadge';
import { roleTooltipDescription } from './utils';
import LockIcon from '../../../../Assets/images/icon-lock.png';
import { AssistMember } from '../../WheelPage';

interface iWheelMembersProps extends RouteComponentProps {
  wheel: iWheel;
  user: iUser;
  setIsLoading?: (isLoading: boolean) => void;
  isLoading?: boolean;
  lockFeature: boolean;
  openCheckinAssistModal?: (member: AssistMember) => void;
}

interface iMembersState {
  members: Array<iWheelMember>;
  search: string;
}

const MembersContainer = styled.div`
  padding: 35px;
  @media (max-width: 1024px) {
    padding: 15px;
  }
`;

const NewFeatureContainer = styled.div`
  padding: 16px 24px;
  border-radius: 4px;
  border: solid 1px #eaeaea;
  background-color: #f8f8f8;
  width: 100%;
  margin-bottom: 24px;
`;

const NewFeatureHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const NewFeatureTitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const LockedWheelMembersContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 12px;
`;

const LockImage = styled.img`
  width: 12px;
  height: 13px;
  margin-left: 4px;
`;

class WheelMembers extends React.Component<iWheelMembersProps, iMembersState> {
  constructor(props) {
    super(props);
    this.state = {
      members: [],
      search: '',
    };
  }

  async componentDidMount() {
    this.props.setIsLoading(true);
    await this.loadData();
    this.props.setIsLoading(false);
  }

  loadData = async () => {
    const res = await MembersClient.getWheelMembersByWheelId(this.props.wheel.id);
    if (res.status === 200) {
      const members = res.data;
      this.setState({
        members,
      });
    } else if (res.status === 403) {
      window.location.reload();
    }
  };

  search = (event) => {
    const search = event.target.value.toLowerCase();
    this.setState({
      search,
    });
  };

  changeRole = async (userId, newRole: WheelMemberRole) => {
    this.props.setIsLoading(true);
    const res = await MembersClient.updateWheelMemberRole(this.props.wheel.id, userId, newRole);
    if (res.status === 200) {
      await this.loadData();
      this.props.setIsLoading(false);
    } else {
      toasterService.addErrorToast(res.data.message);
    }
  };

  deleteMember = async (memberId) => {
    this.props.setIsLoading(true);
    const res = await MembersClient.deleteWheelMember({
      wheelId: this.props.wheel.id,
      userId: memberId,
    });
    if (res.status === 200) {
      await this.loadData();
      this.props.setIsLoading(false);
    } else {
      toasterService.addErrorToast(res.data.message);
    }
  };

  render() {
    const search = this.state.search.toLowerCase();
    const filterBySearch = (member) => {
      const trim = (member.firstName + member.lastName).trim().toLowerCase();
      return trim.includes(search);
    };

    const wheelRole = getWheelRole(this.props.wheel, this.props.user._id);
    const allMembers = this.state.members;
    const searchedMembers = allMembers.filter(filterBySearch);

    const members = searchedMembers.filter((member) => member.memberRole === WheelMemberRole.MEMBER);
    const admins = searchedMembers.filter((member) => member.memberRole === WheelMemberRole.ADMIN);
    const accountabilityBuddies = searchedMembers.filter(
      (member) => member.memberRole === WheelMemberRole.ACCOUNTABILITY_BUDDY
    );
    const myAccountabilityBuddies = accountabilityBuddies.filter((buddy) =>
      buddy.accountabilityBuddyFor.includes(this.props.user._id)
    );
    const filteredAccountabilityBuddies =
      wheelRole === WheelMemberRole.MEMBER ? myAccountabilityBuddies : accountabilityBuddies;

    const isMobile = utils.checkIsPhone();

    const emptyState = (
      <>
        <div className="d-flex justify-content-center mt-5">
          <Text>No results</Text>
        </div>
        <div className="d-flex justify-content-center mt-2">
          <Text size="14px" color="#7f8b96">
            Try a different search term or invite the member that you are looking for to the wheel.
          </Text>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <WheelMembersInviteButton wheel={this.props.wheel} role={WheelMemberRole.MEMBER} />
        </div>
      </>
    );

    const accountabilityBuddyNewFeature = (
      <NewFeatureContainer>
        <NewFeatureHeader>
          <NewFeatureTitleWrapper>
            <Text size="18px" weight={500} margin="0 4px">
              {WheelRoleNaming.accountability_buddy}
            </Text>
            <NewFeatureBadge />
          </NewFeatureTitleWrapper>
          <WheelMembersInviteButton
            wheel={this.props.wheel}
            userId={this.props.user._id}
            role={WheelMemberRole.ACCOUNTABILITY_BUDDY}
          />
        </NewFeatureHeader>
        <Text color="#5d666e" size="12px">
          {roleTooltipDescription.accountability_buddy}
        </Text>
      </NewFeatureContainer>
    );

    const lockedWheelMembers = (title) => {
      const onClick = () => this.props.history.push('/features');
      return (
        <LockedWheelMembersContainer onClick={onClick}>
          <Text size="14px" color="#6a777f" margin="0 4px 0 0">
            {title}
          </Text>
          <LockImage src={LockIcon} />
        </LockedWheelMembersContainer>
      );
    };

    return (
      <MembersContainer>
        <div className="mb-3 d-flex justify-content-end">
          <Search onChange={this.search} width={isMobile ? '100%' : '250px'} />
        </div>
        {!!searchedMembers.length && (
          <div>
            {this.props.wheel.access === WHEEL_ACCESS_TYPES.PRIVATE &&
              (accountabilityBuddies.length ? (
                <WheelMembersList
                  role={WheelMemberRole.ACCOUNTABILITY_BUDDY}
                  wheel={this.props.wheel}
                  roleWheelMembers={filteredAccountabilityBuddies}
                  changeRole={this.changeRole}
                  deleteMember={this.deleteMember}
                  userId={this.props.user._id}
                  allWheelMembers={this.state.members}
                  wheelRole={wheelRole}
                />
              ) : (
                accountabilityBuddyNewFeature
              ))}
            {!this.props.lockFeature ? (
              <WheelMembersList
                role={WheelMemberRole.ADMIN}
                wheel={this.props.wheel}
                roleWheelMembers={admins}
                changeRole={this.changeRole}
                deleteMember={this.deleteMember}
                allWheelMembers={this.state.members}
                wheelRole={wheelRole}
              />
            ) : (
              <>{lockedWheelMembers(`Admins ${admins.length ? `( ${admins.length} )` : ''}`)}</>
            )}
            {wheelRole !== WheelMemberRole.MEMBER && (
              <>
                {!this.props.lockFeature ? (
                  <WheelMembersList
                    role={WheelMemberRole.MEMBER}
                    wheel={this.props.wheel}
                    roleWheelMembers={members}
                    changeRole={this.changeRole}
                    deleteMember={this.deleteMember}
                    allWheelMembers={this.state.members}
                    wheelRole={wheelRole}
                    openCheckinAssistModal={this.props.openCheckinAssistModal}
                  />
                ) : (
                  <>{lockedWheelMembers(`Members ${members.length ? `( ${members.length} )` : ''}`)}</>
                )}
              </>
            )}
          </div>
        )}
        {!searchedMembers.length && this.state.search && emptyState}
      </MembersContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userRD.user,
});

export default connect(mapStateToProps)(withRouter(WheelMembers));
