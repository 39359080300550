import React, { ChangeEvent, Component } from 'react';
import InputGroup from 'reactstrap/es/InputGroup';
import Button from 'reactstrap/es/Button';
import FormGroup from 'reactstrap/es/FormGroup';
import Label from 'reactstrap/es/Label';
import Input from 'reactstrap/es/Input';
import utils from '../Shared/utils';
import Alert from 'reactstrap/es/Alert';
import { MAX_SEGMENTS } from '../../constants';

interface iAddSegmentItemProps {
  addSegment: (params: { name: string; description: string }) => void;
  segments: Array<any>;
}

interface iAddSegmentItemState {
  active: boolean;
  name: string;
  nameTouched: boolean;
  description: string;
  descriptionTouched: boolean;
}

export class AddSegmentItem extends Component<iAddSegmentItemProps, iAddSegmentItemState> {
  readonly state: iAddSegmentItemState = {
    active: false,
    name: '',
    nameTouched: false,
    description: '',
    descriptionTouched: false,
  };

  readonly schema = utils.getFormValidationSchema('segment');
  readonly initialState: iAddSegmentItemState = { ...this.state };

  constructor(props) {
    super(props);
  }

  addSegment = () => {
    this.setState({ active: true });
  };

  cancelCreation = () => {
    this.setState({ active: false });
  };

  changeName = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      name: event.target.value,
      nameTouched: true,
    });
  };

  changeDescription = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      description: event.target.value,
      descriptionTouched: true,
    });
  };

  save = () => {
    this.props.addSegment({
      name: this.state.name,
      description: this.state.description,
    });
    this.setState(this.initialState);
  };

  render() {
    const { name, description, active, nameTouched, descriptionTouched } = this.state;
    const { segments } = this.props;
    const errors: any = utils.validateFields(this.schema, { name, description });

    return (
      <div>
        {!active && (
          <div>
            <Button
              disabled={segments.length >= MAX_SEGMENTS}
              className="btn-red"
              size="sm"
              block
              onClick={this.addSegment}
            >
              Add segment {segments.length >= MAX_SEGMENTS && `(You can't add more than ${MAX_SEGMENTS} segments)`}
            </Button>
          </div>
        )}
        {active && (
          <div>
            <FormGroup>
              <Label className="d-block">Segment name</Label>
              <Input type="text" name="name" value={name} onChange={this.changeName} />
              {errors?.name && nameTouched && (
                <Alert color="danger" className="mt-2">
                  {errors.name}
                </Alert>
              )}
            </FormGroup>
            <FormGroup>
              <Label className="d-block">Segment description</Label>
              <Input type="textarea" name="description" value={description} onChange={this.changeDescription} />
              {errors?.description && descriptionTouched && (
                <Alert color="danger" className="mt-2">
                  {errors.description}
                </Alert>
              )}
            </FormGroup>
            <InputGroup className="justify-content-end mt-3">
              <Button className="ireflect-button mr-2" size="sm" onClick={this.cancelCreation}>
                Close
              </Button>
              <Button className="ireflect-button" size="sm" disabled={!name || !!errors} onClick={this.save}>
                Save
              </Button>
            </InputGroup>
          </div>
        )}
      </div>
    );
  }
}
