import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { analytics } from '../../../../analytics/analytics';
import {
  AccountPlan,
  AggregationUnit,
  iCheckinsAggregation,
  iCommentsAggregation,
  iWheel,
  iWheelMember,
  iWheelPermissions,
} from '../../../../API/interfaces';
import SegmentTags from '../../common/SegmentTags/SegmentTags';
import Wheel from '../../common/Wheel/Wheel';
import HistoryChart from '../../../Shared/HistoryChart/HistoryChart';
import SaveShare from '../../common/SaveShare/SaveShare';
import NoResults from '../../common/NoResults/NoResults';
import CommentsSection from '../../CommentsSection/CommentsSection';
import DateRangeDropdown, {
  DateRangeLabel,
  iDateRangeOptionData,
} from '../../common/DateRangeDropdown/DateRangeDropdown';
import { WHEEL_ACCESS_TYPES } from '../../../../constants';
import AggregationService from '../../common/AggregationService';
import GenerateReport from '../../common/GenerateReportModal/GenerateReport';
import './TeamResults.scss';
import { Padding } from '../../../Shared/Padding/Padding';
import { findIndex, isNumber } from 'lodash';

interface iTeamResultsProps extends RouteComponentProps {
  wheel: iWheel;
  wheelPermissions: iWheelPermissions;
  members: Array<iWheelMember>;
  showCheckinModal: () => void;
  setIsLoading?: (isLoading: boolean) => void;
  isLoading?: boolean;
}

interface iTeamResultsState {
  activeSegmentId: string | null;
  activeSegmentIndex: number | null;
  activeCheckinDate: string | null;
  activeCheckin: iCheckinsAggregation | null;
  checkins: Array<iCheckinsAggregation> | null;
  comments: iCommentsAggregation | null;
  dateRange: iDateRangeOptionData;
  showOnlyCommentsWithText: boolean;
}

class TeamResults extends Component<iTeamResultsProps, iTeamResultsState> {
  readonly aggregationService: AggregationService;

  constructor(props) {
    super(props);

    this.state = {
      activeSegmentId: null,
      activeSegmentIndex: null,
      activeCheckin: null,
      activeCheckinDate: null,
      checkins: null,
      comments: null,
      dateRange: {
        label: 'Latest weekly results' as DateRangeLabel,
        from: null,
        to: null,
        unit: 'week' as AggregationUnit,
      },
      showOnlyCommentsWithText: false,
    };

    this.aggregationService = new AggregationService(props.wheel, true);
  }

  // change active segment
  setActiveSegmentId = (segmentId, segmentIndex) => {
    this.setState({ activeSegmentId: segmentId, activeSegmentIndex: segmentIndex });
  };

  // change active date (timeline entry)
  setActiveCheckinDate = (checkinCreateWeek) => {
    const { activeCheckinDate } = this.state;
    this.setState({ activeCheckinDate: activeCheckinDate === checkinCreateWeek ? null : checkinCreateWeek });
  };

  // change date range
  setDateRange = (dateRange: iDateRangeOptionData) => {
    this.setState({ dateRange });
  };

  // change filter for comments
  setShowOnlyCommentsWithText = (showOnlyCommentsWithText: boolean) => {
    this.setState({ showOnlyCommentsWithText });
  };

  dateRangeDropdownCb = (dateRange: iDateRangeOptionData) => {
    this.setDateRange(dateRange);
    analytics.dateRangeDropdown('teamResults', dateRange.label);
  };

  // load data for a particular date range
  loadData = async (dateRange?: iDateRangeOptionData) => {
    const { activeSegmentId, showOnlyCommentsWithText } = this.state;
    const { wheel, setIsLoading } = this.props;

    setIsLoading(true);
    const checkinsResponse = await this.aggregationService.loadData({
      wheelId: wheel.id,
      from: dateRange.from?.toISODate() || undefined,
      to: dateRange.to?.toISODate() || undefined,
      unit: dateRange.unit,
    });

    const checkins = this.aggregationService.mapCheckinsResponse(checkinsResponse);
    const lastCheckin = checkins[0];

    this.setState(
      {
        activeCheckin: lastCheckin || null,
        activeCheckinDate: lastCheckin?.dateRange || null,
        comments: this.aggregationService.getActiveComments(checkins, showOnlyCommentsWithText, activeSegmentId),
        checkins,
      },
      () => {
        setIsLoading(false);

        const urlSegmentId = new URLSearchParams(this.props.location.search).get('segment');

        const segmentIndex = urlSegmentId
          ? findIndex(lastCheckin?.scores, (score) => score?.segmentId === urlSegmentId)
          : null;
        isNumber(segmentIndex) && this.setActiveSegmentId(urlSegmentId, segmentIndex);
      }
    );
  };

  async componentDidMount() {
    const { setIsLoading } = this.props;
    setIsLoading(true);
    this.loadData(this.state.dateRange);
  }

  componentDidUpdate(prevProps: Readonly<iTeamResultsProps>, prevState: Readonly<iTeamResultsState>) {
    const { wheel } = this.props;
    const { activeCheckinDate, checkins, activeSegmentId, dateRange, showOnlyCommentsWithText } = this.state;

    // change checkin when date is changed
    if (prevState.activeCheckinDate !== activeCheckinDate) {
      this.setState({
        activeCheckin: this.aggregationService.getActiveCheckin(checkins, activeCheckinDate),
      });
    }

    // set comment when segment or comments filter is changed
    if (
      (wheel.isScoreComments && prevState.activeSegmentId !== activeSegmentId) ||
      prevState.showOnlyCommentsWithText !== showOnlyCommentsWithText
    ) {
      this.setState({
        comments: this.aggregationService.getActiveComments(checkins, showOnlyCommentsWithText, activeSegmentId),
      });
    }

    // load data if dateRange is changed
    if (dateRange !== prevState.dateRange) {
      this.loadData(dateRange);
    }
  }

  render() {
    const {
      activeSegmentId,
      checkins,
      activeCheckin,
      activeSegmentIndex,
      activeCheckinDate,
      dateRange,
      comments,
      showOnlyCommentsWithText,
    } = this.state;
    const { wheel, wheelPermissions, members, setIsLoading, showCheckinModal } = this.props;
    const isPrivate = wheel.access === WHEEL_ACCESS_TYPES.PRIVATE;

    const refresh = (): Promise<void> => {
      const { dateRange } = this.state;
      return this.loadData(dateRange);
    };

    if (!checkins) {
      return null;
    }

    if (!checkins.length) {
      return (
        <NoResults
          wheel={wheel}
          showCheckinModal={showCheckinModal}
          dateRange={dateRange}
          setDateRange={this.setDateRange}
          isTeamResults={true}
          shouldShowDateDropdown={dateRange.label !== 'Latest weekly results'}
        />
      );
    }

    const activeSegmentName =
      activeSegmentIndex !== null ? activeCheckin?.scores[activeSegmentIndex]?.segmentName : null;

    return (
      <>
        <div className="team-results">
          <SegmentTags
            averageScore={activeCheckin.averageScore}
            scores={activeCheckin.scores}
            activeSegmentId={activeSegmentId}
            onClick={this.setActiveSegmentId}
          />

          <div className="buttons-container">
            <div className="d-flex align-items-center">
              <SaveShare wheel={wheel} setIsLoading={setIsLoading} />
            </div>

            <div className="timeline-controls">
              <DateRangeDropdown activeOption={dateRange} onChange={this.dateRangeDropdownCb} isTeamResults={true} />

              <GenerateReport
                isTeamReport={true}
                wheel={wheel}
                wheelPermissions={wheelPermissions}
                dateRange={dateRange}
                numberOfMembers={members.length}
                setIsLoading={setIsLoading}
                activeSegmentId={activeSegmentId}
                activeSegmentName={activeSegmentName}
                activeCheckinDate={activeCheckinDate}
                showOnlyCommentsWithText={showOnlyCommentsWithText}
              />
            </div>
          </div>

          <div className="charts-container">
            <Wheel
              maxScale={wheel?.maxScale}
              averageScore={activeCheckin.averageScore}
              scores={activeCheckin.scores}
              activeSegmentId={activeSegmentId}
              onClick={this.setActiveSegmentId}
              width={300}
              numberOfCheckins={activeCheckin.numberOfCheckins}
            />
            <HistoryChart
              checkins={checkins}
              activeSegmentId={activeSegmentId}
              activeSegmentIndex={activeSegmentIndex}
              wheelScale={wheel.maxScale}
              onClick={this.setActiveCheckinDate}
              activeCheckinDate={activeCheckinDate}
            />
          </div>
        </div>
        <Padding mPadding={'0px'}>
          {(activeSegmentIndex === null || wheel.isScoreComments) && (
            <CommentsSection
              activeSegmentName={activeSegmentName}
              activeCheckinDate={activeCheckinDate}
              dateRange={dateRange}
              comments={comments}
              refresh={refresh}
              members={isPrivate ? members : []}
              numberOfCheckins={activeCheckin.numberOfCheckins}
              showOnlyCommentsWithText={showOnlyCommentsWithText}
              setShowOnlyCommentsWithText={this.setShowOnlyCommentsWithText}
              teamAlertThreshold={wheel.wheelCreator.account?.teamAlertThreshold}
              isReplyEnabled={
                wheel.access !== WHEEL_ACCESS_TYPES.PUBLIC &&
                (wheel.wheelCreator.account.plan === AccountPlan.BUSINESS ||
                  wheel.wheelCreator.account.plan === AccountPlan.PERFORMANCE)
              }
            />
          )}
        </Padding>
      </>
    );
  }
}

export default withRouter(TeamResults);
