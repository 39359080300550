import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import services from '../../API/services';
import { iWheel, WheelMemberRoleType, iWheelMember, WheelMemberRole, WheelRoleNaming } from '../../API/interfaces';
import utils, { isWheelMember } from '../Shared/utils';
import { ManageContainer } from '../_Containers/ManageContainer';
import { toasterService } from '../Shared/Toaster/Toaster.service';
import { joinLinkAction } from './JoinWheel.actions';
import { analytics } from '../../analytics/analytics';
import { MembersClient } from '../../API/members.client';
import MobileStep from './MobileStep';
import BrowserStep from './BrowserStep';
import './JoinWheel.scss';
import { showInfoModal } from '../Shared/InfoModal/InfoModal';

interface iMatchParams {
  wheelId: string;
}

interface iProps extends RouteComponentProps<iMatchParams> {
  user: any;
  setShareLink: any;
}

interface iState {
  invitingMemberName: string;
  wheelName: string;
  wheelId: string;
  openInMobAppScreen: boolean;
  invitingMemberId: string;
  inviteRole: string;
  wheelMembers: iWheelMember[];
}

export class JoinWheelComponent extends Component<iProps, iState> {
  readonly isMobile = utils.checkIsPhone();

  state = {
    invitingMemberName: '',
    wheelName: '',
    wheelId: '',
    openInMobAppScreen: false,
    inviteRole: '',
    invitingMemberId: '',
    wheelMembers: [],
  };

  resetShareLinkState = () => {
    this.props.setShareLink(joinLinkAction(''));
  };

  // control "open in mob app" screen
  showOpenInMobAppScreen = () => {
    this.setState({ openInMobAppScreen: true });
  };

  // control "open in mob app" screen
  cancelOpenInMobAppScreen = () => {
    this.props.user ? this.setState({ openInMobAppScreen: false }) : this.redirectToLogin();
  };

  loadWheelData = async (wheelId: string): Promise<iWheel> => {
    const response = await services.getWheelByIdUnauthorized(wheelId);
    if (response.status !== 200) {
      throw Error(response.data.message);
    }

    return response.data as iWheel;
  };

  loadWheelMembersData = async (wheelId: string): Promise<iWheelMember[]> => {
    const response = await MembersClient.getWheelMembersByWheelId(wheelId);
    if (response.status !== 200) {
      throw Error(response.data.message);
    }

    return response.data;
  };

  handleError = (errorMessage: string) => {
    toasterService.addErrorToast(errorMessage);
    this.redirectToHomePage();
  };

  redirectToTheWheel = (isJoiningToWheel = false) => {
    const { location } = this.props;
    const urlParams = new URLSearchParams(location.search);
    const wheelId = new URLSearchParams(location.search).get('wheelId');
    urlParams.delete('wheelId');

    this.props.history.push(`/wheel/${wheelId}?${urlParams.toString()}`, { isJoiningToWheel });
  };

  // dashboard or login page
  redirectToHomePage = () => {
    this.props.history.push('/');
  };

  redirectToLogin = () => {
    const { setShareLink, location, history } = this.props;
    const urlSearchParams = location.search;
    setShareLink(joinLinkAction(urlSearchParams));
    history.push('/login');
  };

  redirectToAccountCheck = () => {
    const { setShareLink, location, history } = this.props;
    const urlSearchParams = location.search;
    setShareLink(joinLinkAction(urlSearchParams));
    history.push('/account-check');
  };

  showWheelMemberModal = () => {
    const { user } = this.props;
    const { wheelMembers, wheelName, inviteRole } = this.state;
    const userWheelRole = wheelMembers.find(({ userId }) => userId === user._id).memberRole;
    let description = `You have already joined the ${wheelName} wheel as an ${WheelRoleNaming[userWheelRole]}.`;
    let status = 'success';
    if (inviteRole === WheelMemberRole.ACCOUNTABILITY_BUDDY) {
      description = `You are already a ${WheelRoleNaming[userWheelRole]} of the ${wheelName}. You can’t join it as ${WheelRoleNaming.accountability_buddy}.`;
      status = 'fail';
    }

    showInfoModal({
      title: '',
      status,
      description,
      onConfirm: this.redirectToTheWheel,
    });
  };

  joinWheel = async () => {
    const { invitingMemberId, wheelId, inviteRole } = this.state;
    const response = await MembersClient.addMemberToWheel({
      wheelId,
      invitingMemberId,
      inviteRole: inviteRole as WheelMemberRoleType,
    });

    this.resetShareLinkState();
    inviteRole === WheelMemberRole.ACCOUNTABILITY_BUDDY ? analytics.joinBuddyRoleWheel() : analytics.joinWheel();
    response.status === 200 ? this.redirectToTheWheel(true) : this.handleError(response.data.message);
  };

  // join wheel, redirect to wheel page or dashboard
  onSubmitJoinWheel = async () => {
    const { user } = this.props;
    const { wheelMembers, invitingMemberId, inviteRole } = this.state;
    const isInWheel = isWheelMember(wheelMembers, user._id);
    const userDataInWheel = wheelMembers.find(({ userId }) => userId === user._id) as iWheelMember;
    const isABforInvitingMember = userDataInWheel?.accountabilityBuddyFor.includes(invitingMemberId);

    if (inviteRole === WheelMemberRole.MEMBER) {
      isInWheel ? this.showWheelMemberModal() : this.joinWheel();
    }
    if (inviteRole === WheelMemberRole.ACCOUNTABILITY_BUDDY) {
      if (isInWheel) {
        if (userDataInWheel.memberRole !== WheelMemberRole.ACCOUNTABILITY_BUDDY) this.showWheelMemberModal();
        else isABforInvitingMember ? this.showWheelMemberModal() : this.joinWheel();
      } else this.joinWheel();
    }
  };

  // cancel join, redirect to dashboard
  onCancelJoinWheel = () => {
    this.resetShareLinkState();
    this.redirectToHomePage();
  };

  async componentDidMount() {
    const { user, location } = this.props;
    const query = new URLSearchParams(location.search);
    const step = query.get('step');
    const wheelId = query.get('wheelId');
    const inviteRole = query.get('role');
    const invitingMemberId = query.get('invitingMemberId') || '';
    const wheel = await this.loadWheelData(wheelId);
    this.resetShareLinkState();
    this.setState({
      inviteRole,
      invitingMemberId,
    });
    const invitingMemberName = invitingMemberId
      ? wheel.members.find((member) => member.userId === invitingMemberId)?.firstName
      : wheel.wheelCreator.firstName;

    // check whether user is logged in
    if (user) {
      analytics.clickInviteLink('loggedIn');

      if (isWheelMember(wheel.members, user._id)) {
        const wheelMembers = await this.loadWheelMembersData(wheelId);
        this.setState({ wheelMembers });
      }

      this.setState({
        invitingMemberName,
        wheelName: wheel.name,
        wheelId: wheel.id,
        // trigger "open in mob app" screen, or proceed with join flow
        openInMobAppScreen: this.shouldRunMobAppFlow(step, wheel),
      });
    } else {
      // logged out
      analytics.clickInviteLink('loggedOut');

      // trigger "open in mob app" screen, or redirect to login
      this.shouldRunMobAppFlow(step, wheel) ? this.showOpenInMobAppScreen() : this.redirectToAccountCheck();
    }
  }

  shouldRunMobAppFlow(step, wheel) {
    return this.isMobile && step !== 'browser' && !wheel?.wheelCreator?.skipMobileJoinFlow;
  }

  render() {
    const { location } = this.props;
    const { wheelName, invitingMemberName, openInMobAppScreen, inviteRole } = this.state;
    const linkParams = location.search;

    return (
      <ManageContainer showLoader={false}>
        <div className="join">
          <div className="join_container">
            <div className="row">
              <div className="col-12">
                <div className="title text-center text-lg-left">Join Wheel</div>
              </div>
            </div>

            <div className="row mt-px-2 ">
              <div className="col-12">
                {openInMobAppScreen ? (
                  <MobileStep linkParams={linkParams} onCancel={this.cancelOpenInMobAppScreen} />
                ) : (
                  <BrowserStep
                    wheelName={wheelName}
                    onSubmit={this.onSubmitJoinWheel}
                    onCancel={this.onCancelJoinWheel}
                    invitingMemberName={invitingMemberName}
                    inviteRole={inviteRole as WheelMemberRoleType}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </ManageContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userRD.user || null,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setShareLink: (shareLink) => {
      dispatch(shareLink);
    },
  };
};

export const JoinWheel = connect(mapStateToProps, mapDispatchToProps)(JoinWheelComponent);
