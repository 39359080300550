import { store } from '../Redux/store';

class Interceptor {
  public request(config) {
    const { user } = store.getState().userRD;
    if (user) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${user.token}`,
      };
    }
    return config;
  }

  public responseError(error) {
    return Promise.reject(error);
  }
}

export default new Interceptor();
